
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  Snackbar,
  Alert,
  Avatar,
  Input,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import SidebarContent from "components/SidebarContent";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import InputFileUpload from "components/ChooseFile";
import Sidebardc from "components/sidebardc.jsx";
 
const AddBanner = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    status: 1,
  });
 
  const handleReset = () => {
    setFormData({
      title: "",
      image: "",
      status:1,
      // name: "",
      // quantity: "",
      // model: "",
      // purchase_price: "",
      // manufacturer: "",
      // sale_price: "",
      // date: "",
      // available: "",
      // description: "",
    });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submittedImage, setSubmittedImage] = useState(null);
 
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
            //Handling Image Change
            const handleImageChange = (e) => {
              const file = e.target.files[0];
         
              if (file) {
                const reader = new FileReader();
         
                reader.onload = () => {
                  if (reader.result) {
                    setFormData((prevData) => ({
                      ...prevData,
                      image: reader.result.split(',')[1], // Store as base64-encoded string
                    }));
                  }
                };
         
                reader.readAsDataURL(file);
              }
            };
         
            // Function to decode Base64 image and display it
            const renderBase64Image = () => {
              if (formData.image) {
                return (
                  <Avatar
                    alt="Image"
                    src={`data:image/png;base64,${formData.image}`}
                    sx={{ width: 100, height: 100 }}
                  />
                );
              }
              return null;
            };
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      status: prevData.status ? 0 : 1,
    }));
    console.log(formData.status);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/dashboard_banners/list/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.image) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                // id:productData.id,
            title: productData.title,
            status: productData.status,
            image: base64Image,
              });
            };
            img.src = `data:image/png;base64,${productData.image}`;
          } else {
            setFormData({
              // id:productData.id,
            title: productData.title,
            status: productData.status,
            image: "",
           
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
 
const handleSubmit = (e) => {
  e.preventDefault();
 
  if (_id) {
    // If _id is defined, it's an edit operation
    const apiUrl = `https://masatv.net/backendserver/api/dashboard_banners/patch/${_id}`; // Edit
    axios
      .patch(apiUrl, formData) // Use axios.patch for the PATCH request
      .then((response) => {
        setSuccessMessage("Add Banner updated successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    // If _id is not defined, it's an add operation
    const apiUrl = "https://masatv.net/backendserver/api/dashboard_banners/post"; // Add
    axios
      .post(apiUrl, formData) // Use axios.post for the POST request
      .then((response) => {
        setSuccessMessage("Add Banner added successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  }
};
 
  return (
   
<Box m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "300px"} mt={isSmallScreen ? "70px" : "40px"}>
    {/* // <Box m="1.5rem 2.5rem" ml="300px"> */}
      <FlexBetween>
        <Header title="DASHBOARDBANNER DETAILS" />
 
        <Box></Box>
      </FlexBetween>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="title"
          label="Title"
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
          margin="normal"
        />
 
        <FormControlLabel
          label="Active"
          labelPlacement="start"
          control={<Checkbox
            name="status"
            checked={formData.status}
            onChange={handleCheckboxToggle}
            />}
          />
<br />
        <label>Upload Image :</label> &nbsp;
      <Input type="file" onChange={handleImageChange} />
 
 
      <br/>
      {/* Display the Base64 image */}
      {renderBase64Image()}
<br></br>
 
 
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button onClick={handleReset} style={{ backgroundColor: "white", color: "black" }}>
            Reset
          </Button>
 
          <Button type="submit" style={{ backgroundColor: "green" }}>Submit</Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
 
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
      {/* <SidebarDC /> */}
    </Box>
  );
};
 
export default AddBanner;
 