
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Snackbar,
  Alert,
  useMediaQuery,
  Avatar,
  Input
} from "@mui/material";
 
import Sidebar from "screens/content";
 
import { ContentCopy, Label } from "@mui/icons-material";
 
import FlexBetween from "components/FlexBetween";
 
import Header from "components/Header";
 
import SidebarContent from "components/SidebarContent";
 
import InputFileUpload from "components/ChooseFile";
 
const AddSong = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
 
  const {checked1, setChecked1} = useState(false);
 
  const [formData, setFormData] = useState({
   
    id: "",
    is_youtube: 1,
    kameraUrl: "",
    streamName: "",
    kameraDurum: "",
    kameraImage: "",
    category_id: "",
    created: "",
    position: "",
    kameraUrl_secondary: "",
    modified: "",
    visible: 1,
  });
 
  const handleReset = () => {
    setFormData({
      id: "",
      is_youtube: "",
      kameraUrl: "",
      streamName: "",
      kameraDurum: "",
      kameraImage: "",
      category_id: "",
      created: "",
      position: "",
      kameraUrl_secondary: "",
      modified: "",
      visible: 1,
    });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submittedImage, setSubmittedImage] = useState(null);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
        //Handling Image Change
        const handleImageChange = (e) => {
          const file = e.target.files[0];
     
          if (file) {
            const reader = new FileReader();
     
            reader.onload = () => {
              if (reader.result) {
                setFormData((prevData) => ({
                  ...prevData,
                  kameraImage: reader.result.split(',')[1], // Store as base64-encoded string
                }));
              }
            };
     
            reader.readAsDataURL(file);
          }
        };
     
        // Function to decode Base64 image and display it
        const renderBase64Image = () => {
          if (formData.kameraImage) {
            return (
              <Avatar
                alt="Image"
                src={`data:image/png;base64,${formData.kameraImage}`}
                sx={{ width: 100, height: 100 }}
              />
            );
          }
          return null;
        };
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      visible: prevData.visible ? 0 : 1,
    }));
    console.log(formData.visible);
  };
  const handleYTCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      is_youtube: prevData.is_youtube ? 0 : 1,
    }));
    console.log(formData.is_youtube);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/songs/get/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.kameraImage) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                id:productData.id,
                is_youtube:productData.is_youtube,
                kameraUrl:productData.kameraUrl,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:base64Image,
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                modified:productData.modified,
              });
            };
            img.src = `data:image/png;base64,${productData.kameraImage}`;
          } else {
            setFormData({
                id:productData.id,
                is_youtube:productData.is_youtube,
                kameraUrl:productData.kameraUrl,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:"",
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                modified:productData.modified,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
const handleSubmit = (e) => {
  e.preventDefault();
 
  if (_id) {
    // If _id is defined, it's an edit operation
    const apiUrl = `https://masatv.net/backendserver/api/songs/patch/${_id}`; // Edit
    axios
      .patch(apiUrl, formData) // Use axios.patch for the PATCH request
      .then((response) => {
        setSuccessMessage("Songs updated successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    // If _id is not defined, it's an add operation
    const apiUrl = "https://masatv.net/backendserver/api/songs/post"; // Add
    axios
      .post(apiUrl, formData) // Use axios.post for the POST request
      .then((response) => {
        setSuccessMessage("Songs added successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  }
};
 
  const [AddSongs, setaddSongs] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/song_categories/find")
      .then((response) => response.json())
      .then((json) => setaddSongs(json.data));
  }, []);
  console.log(AddSongs);
 
 
  return (
    // <Box m="1.5rem 2.5rem" ml="250px">
    <Box m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "350px"} mt={isSmallScreen ? "70px" : "40px"}>
      <FlexBetween>
        <Header title="ADD SONG STREAM" />
 
        <Box></Box>
      </FlexBetween>
 
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="streamName"
          label="Title"
          variant="outlined"
          value={formData.streamName}
          onChange={handleChange}
          margin="normal"
        />
 
       
        <FormControlLabel
          label="Visible"
          labelPlacement="start"
          control={<Checkbox
            name="visible"
            checked={formData.visible}
            onChange={handleCheckboxToggle}
            />}
          />

          <br />
 
 <FormControlLabel
    label="Is Youtube URL?"
    labelPlacement="start"
    control={<Checkbox
            name="is_youtube"
            checked={formData.is_youtube}
            onChange={handleYTCheckboxToggle}
          />}
    />
 
 
        <TextField
          fullWidth
          name="select_channel_image"
          label="Enter Stream URL"
          placeholder="stream for webtv-primary"
          variant="outlined"
          value={formData.select_channel_image}
          onChange={handleChange}
          margin="normal"
        />
 
          Image:
          <FormControl fullWidth margin="normal">
            <Input id="image-upload" type="file" onChange={handleImageChange} />
          </FormControl>
          {renderBase64Image()}
 
<FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">
            Select the Category
          </InputLabel>
          <Select
            label="Select the Category"
            name="select_the_category"
            value={formData.select_the_category}
            onChange={handleChange}
          >
             {/* <MenuItem ></MenuItem> */}
             {AddSongs.map((type) => (
              <MenuItem key={(type.id)} value={(type.id)}>
                {(type.title)}
            </MenuItem>
            ))}
             </Select>
        </FormControl>
        <ButtonGroup variant="contained" aria-label="outlined button group">
        <Button onClick={handleReset}>Reset</Button>
 
          <Button type ="submit">Submit</Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
 
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
 
export default AddSong;
 
 