import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { VisuallyHiddenInput } from "@chakra-ui/visually-hidden";
// import Joi from "joi";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Snackbar,
  Alert,
  
  RadioGroup,
  

  useMediaQuery,
  Avatar,
} from "@mui/material";

import { Input } from "@mui/material";

import { CloudUpload } from "@mui/icons-material";

import SidebarContent from "components/SidebarContent";

import FlexBetween from "components/FlexBetween";

import Header from "components/Header";

import InputFileUpload from "components/ChooseFile";

import { styled } from "@mui/material/styles";
import SidebarMonetize from "components/SidebarMonetize";
import axios from "axios";

const Addpackage = () => {
  const { _id } = useParams();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const [checked, setChecked] = useState(false);
  // const [validationError, setValidationError] = useState(null);

  // const validationSchema = Joi.object({
  //   // id: Joi.string().regex(/^[0-9]+$/, "id").required(),
  //   id:  Joi.string().allow(null).allow(''),
  //   admin_id: Joi.string().allow(null).allow(''),
  //   status: Joi.string().allow(null).allow(''),
    

  //   // name: Joi.string().empty('').min(3).max(30).default('default value').label('Billing Type Name'),
  //   name: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label('enter name'),
  //   image: Joi.string().allow(null).allow(''),
  //   description: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label('Description'),
  //   // name: Joi.string().empty('').label('Billing Type Name'),
  // });


  const [formData, setFormData] = useState({
    id: "",

    id: "",

    name: "",

    image: "",

    description: "",

    admin_id: "",

    
    status: 1,
    


    
  });

const handleReset = () => {
    setFormData({
     
    id: "",

    name: "",


    image: "",

    description: "",

    admin_id: "",

    status: 1,
    });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  //Handling Image Change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
 
    if (file) {
      const reader = new FileReader();
 
      reader.onload = () => {
        if (reader.result) {
          setFormData((prevData) => ({
            ...prevData,
            image: reader.result.split(',')[1], // Store as base64-encoded string
          }));
        }
      };
 
      reader.readAsDataURL(file);
    }
  };
 
  //Render Image while Patch
  // Function to decode Base64 image and display it
  const renderBase64Image = () => {
    if (formData.image) {
      return (
        <Avatar
          alt="Image"
          src={`data:image/png;base64,${formData.image}`}
          sx={{ width: 100, height: 100 }}
        />
      );
    }
    return null;
  };

  const handleCheckboxToggle = () => {
    
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      status: prevData.status ? 0 : 1,
    }));
    console.log(formData.status);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/packages/${_id}`)
        .then((response) => {
          const productData = response.data;
          // Decode the image and set it in the formData
          if (productData.image) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
             
              setFormData({
                id: productData.id,
                title: productData.title,
                name: productData.name,
                description: productData.description,
                admin_id: productData.admin_id,
                status: productData.status,
                image: base64Image,
              });
            };
            img.src = `data:image/png;base64,${productData.image}`;
          } else {
            setFormData({
              id: productData.id,
              title: productData.title,
              name: productData.name,
              image: productData.image,
              description: productData.description,
              admin_id: productData.admin_id,
              status: productData.status,
              image: "", // Set to empty string if there is no image
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);

  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/packages/${_id}`)
        .then((response) => {
          const productData = response.data;
          setFormData({
            id: productData.id,

            title: productData.title,

            name: productData.name,
            image: productData.image,
            description: productData.description,
            admin_id: productData.admin_id,
            status: productData.status,
            // available: productData.available,
            // description: productData.description,
          });
        })
        .catch((error) => {
          console.error('Error fetching product data:', error);
        });
    }
  }, [_id]);
  
  const handleSubmit = (e) => {
 
    e.preventDefault();

//     const validationResult = validationSchema.validate(formData, {
//       abortEarly: false,
//     });
 
//     if (validationResult.error) {
//       setValidationError(validationResult.error.details);
//       return;
//     }
// setValidationError(null);

    if (_id){


      const apiUrl = `https://masatv.net/backendserver/api/packages/patch/${_id}`; // Edit
    axios
      .patch(apiUrl, formData) // Use axios.patch for the PATCH request
      .then((response) => {
        setSuccessMessage("Box Packages updated successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    // If _id is not defined, it's an add operation
    const apiUrl = "https://masatv.net/backendserver/api/packages/post"; // Add
    axios
      .post(apiUrl, formData) // Use axios.post for the POST request
      .then((response) => {
        setSuccessMessage("Box Packages added successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

  // };

  //   // console.log(formData);
  //   axios
  //     .post('https://masatv.net/backendserver/api/categories/post', formData)
  //     .then((response) => console.log(response))
  //     .catch((err) => console.log(err));
  // };

  // const handleChange = (e) => {

  //   const { name, value } = e.target;

  //   setFormData({ ...formData, [name]: value });

  // const handleSubmit = (e) => {

  //   e.preventDefault();

  //   console.log(formData);

  // };


 

  //   width: 1,

  // });

 

  const [categorieslist, setcategorieslist] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/categories/find")
      .then((response) => response.json())
      .then((json) => setcategorieslist(json.data));
  }, []);
  console.log(categorieslist);
 
  const [show_categories, setshow_categories] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/show_categories/find")
      .then((response) => response.json())
      .then((json) => setshow_categories(json.data));
  }, []);
  console.log(show_categories);
 
  const [song_categories, setsong_categories] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/song_categories/find")
      .then((response) => response.json())
      .then((json) => setsong_categories(json.data));
  }, []);
  console.log(song_categories);
 

  const [modcategories, setModCategories] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/mod_categories/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setModCategories(res.data);
      });
  }, []);

  return (
   
    <Box  m={isSmallScreen ? "1rem" : "1.5rem 3.5rem"} ml={isSmallScreen ? "10px" : "250px"} mt={isSmallScreen ? "70px" : "40px"}>
    {/* //  m="1.5rem 2.5rem" ml="250px" */}
      <FlexBetween>
        <Header title="PACKAGE DETAILS" />

       
      </FlexBetween>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="name"
          label="Name"
          variant="outlined"

          value={formData.name}

          onChange={handleChange}
          margin="normal"
        />

        {/* <FormControl>

            <FormControlLabel

            label = " Visible"

              control={

                <Checkbox

                  id="agree"

                  checked={checked}

                  onChange={(e) => setChecked(e.target.checked)}

                />

               

              }

             />

           

          </FormControl> */}

        {/* <InputFileUpload /> */}

        <Box>
          <div>

          <FormControlLabel
          label="Active"
          labelPlacement="start"
          control={<Checkbox
            name="status"
            checked={formData.status}
            onChange={handleCheckboxToggle}
            />}
          />
            
<br />
        <label>Select Package Image :</label> &nbsp;
      <Input type="file" onChange={handleImageChange} />
 
      <br/>
      {/* Display the Base64 image */}
      {renderBase64Image()}
<br></br>

      </div>

    </Box>

        <TextField
          fullWidth
          name="description"
          label="Description"
          variant="outlined"

          value={formData.description}

          onChange={handleChange}
          margin="normal"
        />

        <TextField
          fullWidth
          name="dailyamount"
          label="Daily Amount"
          variant="outlined"

          value={formData.dailyamount}

          onChange={handleChange}
          margin="normal"
        />

        <TextField
          fullWidth
          name="weekly_amount"
          label="Weekly Amount"
          variant="outlined"

          value={formData.weekly_amount}

          onChange={handleChange}
          margin="normal"
        />

        <TextField
          fullWidth
          name="monthly_amount"
          label="Monthly Amount"
          variant="outlined"

          value={formData.monthly_amount}

          onChange={handleChange}
          margin="normal"
        />

        <TextField
          fullWidth
          name="quartely_amount"
          label="Quarterly Amount"
          variant="outlined"

          value={formData.quartely_amount}

          onChange={handleChange}
          margin="normal"
        />

        <TextField
          fullWidth
          name="half_yearly_amount"
          label="Half Yearly Amount"
          variant="outlined"

          value={formData.half_yearly_amount}

          onChange={handleChange}
          margin="normal"
        />

        {/* <TextField

        fullWidth

        name="backup_stream_6"

        label="Backup stream 6"

        variant="outlined"

        value={formData.backup_stream_6}

        onChange={handleChange}

        margin="normal"

      /> */}

        {/* <InputFileUpload/> */}

        {/* <TextField

        fullWidth

        name="select_channel_image"

        label="Select Channel Image"

        variant="outlined"

        value={formData.select_channel_image}

        onChange={handleChange}

        margin="normal"

         

      /> */}

        {/* <TextField

        fullWidth

        name="select_channel_image"

        label={<SelectImageSection/>}

        variant="outlined"

        value={formData.select_channel_image}

        onChange={handleChange}

        margin="normal"

        multiline

        rows={3}

         

      /> */}

        {/* Select1 */}

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">VOD Packages</InputLabel>

          <Select
            label="VOD Packages"
            name="select_the_category"
            value={formData.select_the_category}
            onChange={handleChange}
          >
            <MenuItem>(Choose Category)</MenuItem>
            {categorieslist.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
            ))}
            {/* <MenuItem value="english">2005-Down</MenuItem>

            <MenuItem value="spanish">2006-2012</MenuItem>

            <MenuItem value="african">2013-2015</MenuItem>

            <MenuItem value="arabic">2016-2017</MenuItem>

            <MenuItem value="sports">2018-2019</MenuItem>

            <MenuItem value="kids">2020-2021</MenuItem>

            <MenuItem value="news">2022-2023</MenuItem>

            <MenuItem value="religion">2024-2025</MenuItem>

            <MenuItem value="music">Action</MenuItem>

            <MenuItem value="turkish">ADEL Emam</MenuItem>

            <MenuItem value="iraq">ARABIC</MenuItem>

            <MenuItem value="maghreb">Arabic Old</MenuItem>

            <MenuItem value="german">BIOGRAPHY</MenuItem>

            <MenuItem value="france">COMEDY</MenuItem>

            <MenuItem value="hd_channels">Documentary</MenuItem>

            <MenuItem value="farsi">DRAMA</MenuItem>

            <MenuItem value="Fantasy And SIFI">Fantasy And SIFI</MenuItem>

            <MenuItem value="HOLLYWOOD MOVIES">HOLLYWOOD MOVIES</MenuItem>

            <MenuItem value="HOLLYWOOD MOVIES II">HOLLYWOOD MOVIES II</MenuItem>

            <MenuItem value="Horror">Horror</MenuItem>

            <MenuItem value="INDIAN">INDIAN</MenuItem>

            <MenuItem value="KIDS">KIDS</MenuItem>

            <MenuItem value="MAH.ABD.AIZA">MAH.ABD.AIZA</MenuItem>

            <MenuItem value="Moh. Al. MILIGE">Moh. Al. MILIGE</MenuItem>

            <MenuItem value="Mystery">Mystery</MenuItem>

            <MenuItem value="Nahed Sheref">Nahed Sheref</MenuItem>

            <MenuItem value="Test">Test</MenuItem>

            <MenuItem value="THEATRE">THEATRE</MenuItem>

            <MenuItem value="christian">Christian</MenuItem>

            <MenuItem value="TOP 12">TOP 12</MenuItem>

            {/* Add more language MenuItems as needed */}

            {/* Select2 */}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="user">MOD Packages</InputLabel>

          <Select
            label="Mod Packages"
            name="select_category"
            value={formData.select_category}
            onChange={handleChange}
          >
            <MenuItem value="17">(Choose Mod Category)</MenuItem>
            {modcategories.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
          </FormControl>

        {/* Select3 */}

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">Audio Packages</InputLabel>

          <Select
            label="Audio Packages"
            name="select_the_category"
            value={formData.select_the_category}
            onChange={handleChange}
          >
            <MenuItem value="1">Arabic</MenuItem>

            <MenuItem value="5">TOP RADIO</MenuItem>

            <MenuItem value="6">Turkish</MenuItem>

            {/* Add more language MenuItems as needed */}
          </Select>
        </FormControl>

        {/* Select4 */}

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">
            Live TV Packages
          </InputLabel>

          <Select
            label="Live TV Packages"
            name="select_the_category"
            value={formData.select_the_category}
            onChange={handleChange}
          >
            <MenuItem value="1">OSN TEST</MenuItem>

            <MenuItem value="7">KURDISH</MenuItem>

            <MenuItem value="6">AFRICAN</MenuItem>

            <MenuItem value="2">ARABIC</MenuItem>

            <MenuItem value="4">SPORTS</MenuItem>

            <MenuItem value="9">KIDS</MenuItem>

            <MenuItem value="10">NEWS</MenuItem>

            <MenuItem value="11">RELIGION</MenuItem>

            <MenuItem value="8">MUSIC</MenuItem>

            <MenuItem value="3">TURKISH</MenuItem>

            <MenuItem value="5">IRAQ</MenuItem>

            <MenuItem value="16">MAGHREB</MenuItem>

            <MenuItem value="17">GERMAN</MenuItem>

            <MenuItem value="22">FRANCE</MenuItem>

            <MenuItem value="49">HD channels</MenuItem>

            <MenuItem value="41">Farsi</MenuItem>

            <MenuItem value="43">Important</MenuItem>

            <MenuItem value="44">Problem</MenuItem>

            <MenuItem value="45">AFGHANISTAN</MenuItem>

            <MenuItem value="47">Christian</MenuItem>

            {/* Add more language MenuItems as needed */}
          </Select>
        </FormControl>

        {/* Select5 */}

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">
            VideoLive TV Packages
          </InputLabel>

          <Select
            label="VideoLive TV Packages"
            name="select_the_category"
            value={formData.select_the_category}
            onChange={handleChange}
          >
            <MenuItem value="28">TS-ARABIC</MenuItem>

            <MenuItem value="30">FARSI</MenuItem>

            <MenuItem value="31">خرزة زرقا</MenuItem>

            {/* Add more language MenuItems as needed */}
          </Select>
        </FormControl>

        {/* Select6 */}

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">Shows Packages</InputLabel>

          <Select
            label="Shows Packages"
            name="select_the_category"
            value={formData.select_the_category}
            onChange={handleChange}
          >
            {/* <MenuItem ></MenuItem> */}
            {show_categories.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
            ))}
            {/* <MenuItem value="172">إسلاميات</MenuItem>

            <MenuItem value="173">طبخ</MenuItem>

            <MenuItem value="174">ترفيه</MenuItem>

            <MenuItem value="175">صحة وجمال</MenuItem>

            <MenuItem value="180">ers</MenuItem>

            {/* Add more language MenuItems as needed */}
          </Select>
        </FormControl>

        {/* Select7 */}

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">Songs Packages</InputLabel>

          <Select
            label="Songs Packages"
            name="select_the_category"
            value={formData.select_the_category}
            onChange={handleChange}
          >
            {song_categories.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
            ))}

            {/* <MenuItem value="29">Arabic</MenuItem>

<MenuItem value="30">Pop</MenuItem>

            {/* Add more language MenuItems as needed */}
          </Select>
        </FormControl>

        {/* {validationError && (
<div style={{ color: 'red' }}>
            {validationError.map((error) => (
<div>{error.message}</div>
            ))}
</div>
      )} */}

        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button type="reset" onClick={handleReset}>
            Reset
          </Button>

          <Button type="submit">Submit</Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Addpackage;
