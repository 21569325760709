
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import Joi from "joi";
import { useParams } from 'react-router-dom';
import { TextField,
         Button,
         ButtonGroup,
         FormControl,
         InputLabel,
         Select,
         MenuItem,
         FormControlLabel,
         Checkbox,
         Box,
         Snackbar,
         Alert,
        useMediaQuery,
        Avatar
      }
 from '@mui/material';
import { Input } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import InputFileUpload from 'components/ChooseFile';
// import SelectImageSection from 'components/ChooseFile';
 
 
const AddShow= () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
  // const [validationError, setValidationError] = useState(null);
 
  // const validationSchema = Joi.object(
  //   {
  //     streamName: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(1).max(30).required().label("streamName"),
  //     id: Joi.string().allow('').allow(null),
  //     visible: Joi.allow('').allow(null),
  //     enter_stream_URL: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('enter_stream_URL'),
  //     trailer: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('trailer'),
  //     description: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('description'),
  //     language: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("language"),
  //     duration: Joi.string().regex(/^([01]\d|2[0-3]):([0-5]\d)$/).required().label(" duration"),  
  //     year: Joi.number().precision(4).required().label(" year"),
  //     studio: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("studio"),
  //     producer: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("producer"),
  //     director: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("director"),
  //     ratings: Joi.number().precision(2).required().label(" ratings"),
  //     actors: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("actors"),
  //     price: Joi.number().required().precision(2).label("price"),
  //     kameraUrl: Joi.string().allow('').allow(null),
  //     kameraDurum: Joi.string().allow('').allow(null),
  //     kameraImage: Joi.string().allow('').allow(null),
  //     category_id: Joi.string().allow('').allow(null),
  //     created: Joi.string().allow('').allow(null),
  //     position: Joi.string().allow('').allow(null),
  //     modified: Joi.string().allow('').allow(null),
  //     kameraUrl_secondary: Joi.string().allow('').allow(null),
  //     // modified: Joi.string().allow('').allow(null),
  //   });
 
  const [formData, setFormData] = useState({
    // stream_for_webtv:"",
     id:"",
      kameraUrl:"",
      streamName:"",
      kameraDurum:"",
      kameraImage:"",
      category_id:"",
      created:"",
      position:"",
      modified:"",
      kameraUrl_secondary:"",
      visible:"1",
      trailer:"",
      description:"",
      language:"",
      duration:"",
      year:"",
      studio:"",
      producer:"",
      director:"",
      actors:"",
      ratings:"",
      price:"",
  });
 
  const handleReset = () => {
    setFormData({
      // stream_for_webtv:"",
      id:"",
      kameraUrl:"",
      streamName:"",
      kameraDurum:"",
      kameraImage:"",
      category_id:"",
      created:"",
      position:"",
      modified:"",
      kameraUrl_secondary:"",
      visible:"1",
      trailer:"",
      description:"",
      language:"",
      duration:"",
      year:"",
      studio:"",
      producer:"",
      director:"",
      actors:"",
      ratings:"",
      price:"",
 
    });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submittedImage, setSubmittedImage] = useState(null);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
        //Handling Image Change
        const handleImageChange = (e) => {
          const file = e.target.files[0];
     
          if (file) {
            const reader = new FileReader();
     
            reader.onload = () => {
              if (reader.result) {
                setFormData((prevData) => ({
                  ...prevData,
                  kameraImage: reader.result.split(',')[1], // Store as base64-encoded string
                }));
              }
            };
     
            reader.readAsDataURL(file);
          }
        };
     
        // Function to decode Base64 image and display it
        const renderBase64Image = () => {
          if (formData.kameraImage) {
            return (
              <Avatar
                alt="Image"
                src={`data:image/png;base64,${formData.kameraImage}`}
                sx={{ width: 100, height: 100 }}
              />
            );
          }
          return null;
        };
 
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      visible: prevData.visible ? 0 : 1,
    }));
    console.log(formData.visible);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/shows/find/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.kameraImage) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                id:productData.id,
                kameraUrl:productData.kameraUrl,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:base64Image,
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                modified:productData.modified,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                trailer:productData.trailer,
                description:productData.description,
                language:productData.language,
                duration:productData.duration,
                year:productData.year,
                studio:productData.studio,
                producer:productData.producer,
                director:productData.director,
                actors:productData.actors,
                ratings:productData.ratings,
                price:productData.price,
              });
            };
            img.src = `data:image/png;base64,${productData.kameraImage}`;
          } else {
            setFormData({
              id:productData.id,
                kameraUrl:productData.kameraUrl,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:"",
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                modified:productData.modified,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                trailer:productData.trailer,
                description:productData.description,
                language:productData.language,
                duration:productData.duration,
                year:productData.year,
                studio:productData.studio,
                producer:productData.producer,
                director:productData.director,
                actors:productData.actors,
                ratings:productData.ratings,
                price:productData.price,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
 
const handleSubmit = (e) => {
  e.preventDefault();
  // const validationResult = validationSchema.validate(formData, {
  //   abortEarly: false,
  // });
 
  // if (validationResult.error) {
  //   setValidationError(validationResult.error.details);
  //   return ;
  // }
 
  // setValidationError(null);
 
  if (_id) {
    // If _id is defined, it's an edit operation
    const apiUrl = `https://masatv.net/backendserver/api/shows/patch/${_id}`; // Edit
    axios
      .patch(apiUrl, formData) // Use axios.patch for the PATCH request
      .then((response) => {
        setSuccessMessage("Show Episodes updated successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    // If _id is not defined, it's an add operation
    const apiUrl = "https://masatv.net/backendserver/api/shows/post"; // Add
    axios
      .post(apiUrl, formData) // Use axios.post for the POST request
      .then((response) => {
        setSuccessMessage("Show Episodes added successfully!");
        setSnackbarOpen(true);
        handleReset();
      })
      .catch((err) => {
        console.error(err);
      });
  }
};
 
  const [Addshowepisodes, setaddshowepisodes] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/show_sub_categories/find")
      .then((response) => response.json())
      .then((json) => setaddshowepisodes(json.data));
  }, []);
  console.log(Addshowepisodes);
 
  return (
    // <Box m="1.5rem 2.5rem" ml="250px">
    <Box m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "320px"} mt={isSmallScreen ? "70px" : "40px"}>
    <FlexBetween>
 
    <Header title="ADD SERIES" />
 
 
    <Box></Box>
  </FlexBetween>
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        name="streamName"
        label="Title"
        variant="outlined"
        value={formData.streamName}
        onChange={handleChange}
        margin="normal"
      />
     
         <FormControlLabel
          label="Visible"
          labelPlacement="start"
          control={<Checkbox
            name="visible"
            checked={formData.visible}
            onChange={handleCheckboxToggle}
            />}
          />
     
      <TextField
        fullWidth
        name="enter_stream_URL"
        label="Enter Stream URL"
        variant="outlined"
        value={formData.enter_stream_URL}
        onChange={handleChange}
        margin="normal"
      />
     
      <TextField
        fullWidth
        name="trailer"
        label="Add Trailer"
        variant="outlined"
        value={formData.trailer}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="description"
        label="Description"
        variant="outlined"
        value={formData.description}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="language"
        label="Language"
        variant="outlined"
        value={formData.language}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="duration"
        label="Duration"
        variant="outlined"
        value={formData.duration}
        onChange={handleChange}
        margin="normal"
      />
        <TextField
        fullWidth
        name="year"
        label="Year"
        variant="outlined"
        value={formData.year}
        onChange={handleChange}
        margin="normal"
      />
        <TextField
        fullWidth
        name="studio"
        label="Studio"
        variant="outlined"
        value={formData.studio}
        onChange={handleChange}
        margin="normal"
      />
         <TextField
        fullWidth
        name="producer"
        label="Producer"
        variant="outlined"
        value={formData.producer}
        onChange={handleChange}
        margin="normal"
         
      />
      <TextField
        fullWidth
        name="director"
        label="Director"
        variant="outlined"
        value={formData.director}
        onChange={handleChange}
        margin="normal"
         
      />
<TextField
        fullWidth
        name="actors"
        label="Actors"
        variant="outlined"
        value={formData.actors}
        onChange={handleChange}
        margin="normal"
         
      />
<TextField
        fullWidth
        name="ratings"
        label="Ratings"
        variant="outlined"
        value={formData.ratings}
        onChange={handleChange}
        margin="normal"
         
      />
<TextField
        fullWidth
        name="price"
        label="Price"
        variant="outlined"
        value={formData.price}
        onChange={handleChange}
        margin="normal"
         
      />
 
      Image:
      <FormControl fullWidth margin="normal">
            <Input id="image-upload" type="file" onChange={handleImageChange} />
      </FormControl>
      {renderBase64Image()}
 
        <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel htmlFor="select_the_category">Select the Category</InputLabel>
        <Select
          label="Select the category"
          name="category_id"
          value={formData.category_id}
          onChange={handleChange}
        >
 
            {Addshowepisodes.map((type) => (
              <MenuItem key={(type.id)} value={(type.id)}>
                {(type.title)}
            </MenuItem>
            ))}
 
          {/* Add more language options as needed */}
        </Select>
      </FormControl>
     
 
      {/* {validationError && (
          <div style={{ color: 'red' }}>
            {validationError.map((error) => (
              <div>{error.message}</div>
            ))}
            </div>
      )} */}
 
 
 
      <ButtonGroup variant="contained" aria-label="outlined button group">
      <Button type="reset" onClick={handleReset}>Reset</Button>
 
<Button type ="submit">Submit</Button>
</ButtonGroup>
</form>
<Snackbar
open={snackbarOpen}
autoHideDuration={6000} // Adjust the duration as needed
onClose={handleCloseSnackbar}
 
>
<Alert
onClose={handleCloseSnackbar}
severity="success"
variant="filled"
>
{successMessage}
</Alert>
</Snackbar>
    </Box>
  );
};
 
export default AddShow;
 