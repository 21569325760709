import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import {
  Typography,
  useMediaQuery,
  Button,
  ButtonGroup,
  Snackbar,
  Alert,
  Avatar
} from '@mui/material';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import StatBox from 'components/StatBox';

const CustomerNotifications = () => {
  const [notificationType, setNotificationType] = useState('');
  const { _id } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  const [submittedData, setSubmittedData] = useState(null);
  
  const [submittedImage, setSubmittedImage] = useState(null);

  const handleRadioChange = (event) => {
    setNotificationType(event.target.value);
  };

  const handleTextChange = (event) => {
    setFormData({
      ...formData,
      text: event.target.value,
    });
  };

  //Handling Image Change
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          setFormData((prevData) => ({
            ...prevData,
            image: reader.result.split(',')[1], // Store as base64-encoded string
          }));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  // Function to decode Base64 image and display it
  const renderBase64Image = () => {
    if (setSubmittedImage) {
      return (
        <Avatar
          alt="Image"
          src={`data:image/png;base64,${submittedImage}`}
          sx={{ width: 100, height: 100 }}
        />
      );
    }
    return null;
  };

  const handletextbutton = () => {
    setFormData((prevData) => ({
      ...prevData,
      type: prevData.type ? '' : 'text',
    }));
  };

  const handleimagebutton = () => {
    setFormData((prevData) => ({
      ...prevData,
      type: prevData.type ? '' : 'image',
    }));
  };

  const [formData, setFormData] = useState({
    text: '',
    type: '',
    image: '',
    created_by: '',
    created_at: '',
    updated_at: '',
    updated_by: '',
  });

  const handleReset = () => {
    setFormData({
      text: '',
      type: '',
      image: '',
      created_by: '',
      created_at: '',
      updated_at: '',
      updated_by: '',
    });
  };

  const [successMessage, setSuccessMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (_id) {
      axios
        .get(`https://masatv.net/backendserver/api/customer_notifications/get/${_id}`)
        .then((response) => {
          const productData = response.data;

          // Decode the image and set it in the formData
          if (productData.image) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];

              setFormData({
                text: productData.text,
                image: base64Image,
              });
            };
            img.src = `data:image/png;base64,${productData.image}`;
          } else {
            setFormData({
              text: productData.text,
              image: "", // Set to empty string if there is no image
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching product data:', error);
        });
    }
  }, [_id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (notificationType === 'text') {
      setSubmittedData({
        text: formData.text,
      });
    } else if (notificationType === 'image') {
      setSubmittedData({
        image: formData.image,
      });
      setSubmittedImage(formData.image)
    }

    if (_id) {
      const apiUrl = `https://masatv.net/backendserver/api/customer_notifications/patch/${_id}`;
      axios
        .patch(apiUrl, formData)
        .then((response) => {
          setSuccessMessage('Notification updated successfully!');
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const apiUrl = 'https://masatv.net/backendserver/api/customer_notifications/post';
      axios
        .post(apiUrl, formData)
        .then((response) => {
          setSuccessMessage('Notification added successfully!');
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <Box m={isSmallScreen ? '1rem' : '1.5rem 2.5rem'} ml={isSmallScreen ? '10px' : '300px'} mt={isSmallScreen ? '70px' : '80px'}>
      <StatBox title="Customer Notifications"></StatBox>

      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="notification-type"
          name="notification-type"
          value={notificationType}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="text"
            control={<Radio />}
            label="Text"
            textbutton={formData.type}
            onChange={handletextbutton}
          />

          <FormControlLabel
            value="image"
            control={<Radio />}
            label="Image"
            textbutton={formData.type}
            onChange={handleimagebutton}
          />
        </RadioGroup>
      </FormControl>
      <form onSubmit={handleSubmit}>
        {notificationType === 'text' && (
          <TextField
            label="Enter Text"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.text}
            onChange={handleTextChange}
          />
        )}

        {notificationType === 'image' && (
          <FormControl fullWidth margin="normal">
            <Input id="image-upload" type="file" onChange={handleImageChange} />
          </FormControl>
        )}

      <br/>
      {/* Display the Base64 image */}
      {/* {renderBase64Image()} */}

        <br></br>
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button onClick={handleReset} style={{ backgroundColor: 'White', color: 'black' }}>
            Reset
          </Button>
          <Button type="submit" style={{ backgroundColor: 'green' }}>
            Submit
          </Button>
        </ButtonGroup>
      </form>

      <br></br>

       {/* notification for text */}

      {submittedData && notificationType === 'text' && (
        <div style={{ backgroundColor: 'transparent', color: 'white', padding: '6px', borderRadius: '10px' }}>
          <h3>Customer Notification : <span style={{ color: 'black' }}>{submittedData.text} </span>- <span style={{ color: 'green' }}>Active Notification Text</span> </h3>
        </div>
      )}


       {/* notification for image */}

      {submittedData && notificationType === 'image' && (
        <div style={{ backgroundColor: 'transparent', color: 'white', padding: '6px', borderRadius: '10px' }}>
          <h3>Customer Notification : {renderBase64Image()} - <span style={{ color: 'green' }}>Active Image</span> </h3>
        </div>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" variant="filled">
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomerNotifications;
