
 
import React, { useState } from 'react';
import { TextField, Button, ButtonGroup, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Box,useMediaQuery,Snackbar,
 Alert,Avatar,Input } from '@mui/material';
 import SidebarContent from 'components/SidebarContent';
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import InputFileUpload from 'components/ChooseFile';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// import Joi from "joi";

 
const AddSeries = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [checked, setChecked] = useState(false);
  // const [validationError, setValidationError] = useState(null);
  const { _id } = useParams();
  // const validationSchema = Joi.object(
  //   // const timeSchema = Joi.date().iso().strict().max('now').required();
  //   { price: Joi.number().required().precision(2).label("price"),
  //   ratings: Joi.string().regex(/^\d{1}$/).required().label(" ratings"),
  //   duration: Joi.string().regex(/^([01]\d|2[0-3]):([0-5]\d)$/).required(),
  //   // duration: Joi.number().precision(2).required().label(" duration"),
  //   year: Joi.number().required().precision(2).label(" year"),
  //   // sale_price: Joi.number().required().precision(2).label(" sale_price"),
  //   // purchase_price: Joi.number().required().label(" purchase_price"),
  //   // Add_Trailer: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("Add_Trailer"),
  //   enter_stream_url: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("Enter_Stream_Url"),
  //   // anguage: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("language"),
  //   producer: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("producer"),
  //   director: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("director"),
  //   studio: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("studio"),
  //   actors: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("actors"),
  //   language: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("language"),
  //   streamName: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("streamName"),
  //   description: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("description"),
  //   visible: Joi.allow('').allow(null),
  //   id: Joi.string().allow('').allow(null),
  //   kameraUrl: Joi.string().allow('').allow(null),
  //   streamName: Joi.string().allow('').allow(null),
  //   kameraDurum: Joi.string().allow('').allow(null),
  //   kameraImage: Joi.string().allow('').allow(null),
  //   category_id: Joi.string().allow('').allow(null),
  //   created: Joi.string().allow('').allow(null),
  //   position: Joi.string().allow('').allow(null),
  //   modified: Joi.string().allow('').allow(null),
  //   kameraUrl_secondary: Joi.string().allow('').allow(null),
  //   add_trailer: Joi.string().allow('').allow(null),
  //   trailer: Joi.string().allow('').allow(null),
  //   select_the_category: Joi.string().allow('').allow(null),
  // });
  const [formData, setFormData] = useState({
    id: '',
    kameraUrl: '',
    streamName: '',
    kameraDurum: '',
    kameraImage: '',
    category_id: '',
    created: '',
    position: '',
    modified: '',
    kameraUrl_secondary:'',
    visible: "1",
    trailer: '',
    description: '',
    language: '',
    duration: '',
    year: '',
    studio: '',
    producer: '',
    director: '',
    actors: '',
    ratings: '',
    price: '',
   
 
  });
 
  const handleReset = () => {
    setFormData({
      id: '',
      kameraUrl: '',
      streamName: '',
      kameraDurum: '',
      kameraImage: '',
      category_id: '',
      created: '',
      position: '',
      modified: '',
      kameraUrl_secondary:'',
      visible: "1",
      trailer: '',
      description: '',
      language: '',
      duration: '',
      year: '',
      studio: '',
      producer: '',
      director: '',
      actors: '',
      ratings: '',
      price: '',
     });
  };
 
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submittedImage, setSubmittedImage] = useState(null);
 
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
        //Handling Image Change
        const handleImageChange = (e) => {
          const file = e.target.files[0];
     
          if (file) {
            const reader = new FileReader();
     
            reader.onload = () => {
              if (reader.result) {
                setFormData((prevData) => ({
                  ...prevData,
                  kameraImage: reader.result.split(',')[1], // Store as base64-encoded string
                }));
              }
            };
     
            reader.readAsDataURL(file);
          }
        };
     
        // Function to decode Base64 image and display it
        const renderBase64Image = () => {
          if (formData.kameraImage) {
            return (
              <Avatar
                alt="Image"
                src={`data:image/png;base64,${formData.kameraImage}`}
                sx={{ width: 100, height: 100 }}
              />
            );
          }
          return null;
        };
 
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      visible: prevData.visible ? 0 : 1,
    }));
    console.log(formData.visible);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/mods/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.kameraImage) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                id:productData.id,
                kameraUrl:productData.kameraUrl,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:base64Image,
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                modified:productData.modified,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                trailer:productData.trailer,
                description:productData.description,
                language:productData.language,
                duration:productData.duration,
                year:productData.year,
                studio:productData.studio,
                producer:productData.producer,
                director:productData.director,
                actors:productData.actors,
                ratings:productData.ratings,
                price:productData.price,
               
              });
            };
            img.src = `data:image/png;base64,${productData.kameraImage}`;
          } else {
            setFormData({
              id:productData.id,
              kameraUrl:productData.kameraUrl,
              streamName:productData.streamName,
              kameraDurum:productData.kameraDurum,
              kameraImage:"",
              category_id:productData.category_id,
              created:productData.created,
              position:productData.position,
              modified:productData.modified,
              kameraUrl_secondary:productData.kameraUrl_secondary,
              trailer:productData.trailer,
              description:productData.description,
              language:productData.language,
              duration:productData.duration,
              year:productData.year,
              studio:productData.studio,
              producer:productData.producer,
              director:productData.director,
              actors:productData.actors,
              ratings:productData.ratings,
              price:productData.price,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // const validationResult = validationSchema.validate(formData, {
    //   abortEarly: false,
    // });
 
    // if (validationResult.error) {
    //   setValidationError(validationResult.error.details);
    //   return ;
    // }
 
    // setValidationError(null);
    console.log(formData);
 
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/mods/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData) // Use axios.patch for the PATCH request
        .then((response) => {
          setSuccessMessage("Series updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/mods/post"; // Add
      axios
        .post(apiUrl, formData) // Use axios.post for the POST request
        .then((response) => {
          setSuccessMessage("Series added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
 
  const [addSeriesEpisodes, setAddSeriesEpisodes] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/mods/find")
      .then((response) => response.json())
      .then((json) => setAddSeriesEpisodes(json.data));
  }, []);
  console.log(addSeriesEpisodes);
 
  return (
 
    <Box m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "350px"} mt={isSmallScreen ? "70px" : "40px"}>
    {/* <Box m="1.5rem 2.5rem" ml="250px"> */}
    <FlexBetween>
    <Header title="ADD SERIES" />
   
    <Box></Box>
  </FlexBetween>
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        name="streamName"
        label="Title"
        variant="outlined"
        value={formData.streamName}
        onChange={handleChange}
        margin="normal"
      />
      <FormControlLabel
        label="Visible"
        labelPlacement="start"
        control={<Checkbox
            name="visible"
            checked={formData.visible}
            onChange={handleCheckboxToggle}
          />}
    />
     
      <TextField
        fullWidth
        name="enter_stream_url"
        label="Enter Stream Url"
        variant="outlined"
        value={formData.enter_stream_url}
        onChange={handleChange}
        margin="normal"
      />
     
      <TextField
        fullWidth
        name="add_trailer"
        label="Add Trailer"
        variant="outlined"
        value={formData.add_trailer}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="description"
        label="Description"
        variant="outlined"
        value={formData.description}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
      />
      {/* <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel htmlFor="language">Language</InputLabel>
        <Select
          label="Language"
          name="language"
          value={formData.language}
          onChange={handleChange}
        > */}
          {/* <MenuItem value="english">English</MenuItem>
          <MenuItem value="spanish">Spanish</MenuItem>
          <MenuItem value="french">French</MenuItem> */}
          {/* Add more language options as needed */}
        {/* </Select>
      </FormControl> */}
      <TextField
        fullWidth
        name="language"
        label="Language"
        variant="outlined"
        value={formData.language}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="duration"
        label="Duration"
        variant="outlined"
        value={formData.duration}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="year"
        label="Year"
        variant="outlined"
        value={formData.year}
        onChange={handleChange}
        margin="normal"
      />
        <TextField
        fullWidth
        name="studio"
        label="Studio"
        variant="outlined"
        value={formData.studio}
        onChange={handleChange}
        margin="normal"
      />
        <TextField
        fullWidth
        name="producer"
        label="Producer"
        variant="outlined"
        value={formData.producer}
        onChange={handleChange}
        margin="normal"
      />
        <TextField
        fullWidth
        name="director"
        label="Director"
        variant="outlined"
        value={formData.director}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="actors"
        label="Actors"
        variant="outlined"
        value={formData.actors}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        name="ratings"
        label="Ratings"
        variant="outlined"
        value={formData.ratings}
        onChange={handleChange}
        margin="normal"
      />
         <TextField
        fullWidth
        name="price"
        label="Price"
        variant="outlined"
        value={formData.price}
        onChange={handleChange}
        margin="normal"
      />
 
 <br />
        <label>Upload Image :</label> &nbsp;
      <Input type="file" onChange={handleImageChange} />
 
 
      <br/>
      {/* Display the Base64 image */}
      {renderBase64Image()}
<br></br>
 
      {/* <InputFileUpload/> */}
         {/* <TextField
        fullWidth
        name="select_channel_image"
        label="Select_Channel_Image"
        variant="outlined"
        value={formData.select_channel_image}
        onChange={handleChange}
        margin="normal"
      /> */}
         <TextField
        fullWidth
        name="select_the_category"
        label="Select the Category"
        variant="outlined"
        value={formData.select_the_category}
        onChange={handleChange}
        margin="normal"
      />
     
     {/* {validationError && (
          <div style={{ color: 'red' }}>
            {validationError.map((error) => (
              <div>{error.message}</div>
            ))}
            </div>
      )} */}
 
      <ButtonGroup variant="contained" aria-label="outlined button group">
  <Button type="reset" onClick={handleReset}>Reset</Button>
  <Button type='submit'>Submit</Button>
</ButtonGroup>
    </form>
    {/* <SidebarContent /> */}
    <Snackbar
open={snackbarOpen}
autoHideDuration={6000} // Adjust the duration as needed
onClose={handleCloseSnackbar}
 
>
<Alert
onClose={handleCloseSnackbar}
severity="success"
variant="filled"
>
{successMessage}
</Alert>
</Snackbar>
    </Box>
  );
};
 
export default AddSeries;