
import React, { useState, useEffect } from "react";
import axios from "axios"
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
 
import { Input } from "@mui/material";
 
import { CloudUpload } from "@mui/icons-material";
 
import SidebarContent from "components/SidebarContent";
 
import FlexBetween from "components/FlexBetween";
 
import Header from "components/Header";
 
import InputFileUpload from "components/ChooseFile";
 
// import Adminsidebar from 'components/adminsidebar';
 
const AddMessage2 = () => {
  const [checked, setChecked] = useState(false);
  const [passchecked, setPassChecked] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { _id } = useParams();
  const [visiblechecked, setVisibleChecked] = useState(0);
 
 
  // const handleCheckboxToggle = (e)=>
  // {
  //   console.log(e.target.checked)
  //   setVisibleChecked(e.target.checked);
  //   console.log("....",visiblechecked);
  // }
 
  const [formData, setFormData] = useState({
    id:"",
    title:"",
    description:"",
    user_ids:"",
    start_date:"",
    repeat_intervals:"",
    is_active:1,          //initial Value
    created:"",
    modified:"",
  });
 
 
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
 
  const handleReset = () => {
    setFormData({
    id:"",
    title:"",
    description:"",
    user_ids:"",
    start_date:"",
    repeat_intervals:"",
    is_active:1,
    created:"",
    modified:"",
    });
  };
 
  const handleChange = (e) => {
    const { name, value } = e.target;
 
    setFormData({ ...formData, [name]: value });
  };
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      is_active: prevData.is_active ? 0 : 1,
    }));
    console.log(formData.is_active);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/saz1_messages/get/${_id}`)
        .then((response) => {
          const productData = response.data;
          setFormData({
            title:productData.title,
            description:productData.description,
            is_active: productData.is_active,
            // created:productData.created,
            // start_date:productData.start_date,
            // repeat_intervals:productData.repeat_intervals,
            // is_active:productData.is_active,
          });
        })
        .catch((error) => {
          console.error('Error fetching product data:', error);
        });
    }
  }, [_id]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/saz1_messages/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData)
        .then((response) => {
          setSuccessMessage("Saz1 Messages updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/saz1_messages/post"; // Add
      console.log(formData,'abcd');
      axios
        .post(apiUrl, formData)
        .then((response) => {
          setSuccessMessage("Saz1 Messages added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
 
 
 
 
  const [products, setProducts] = useState([]);
 
  // Make an API request to fetch payment types data from your server
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/saz1_messages/get")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setProducts(res.data);
      });
  }, []);
 
 
  return (
    <Box m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "350px"} mt={isSmallScreen ? "70px" : "40px"}>
      <FlexBetween>
        <Box></Box>
      </FlexBetween>
 
      <h3>APPMESSAGE ADD </h3>
 
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="title"
          label="Title"
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
          margin="normal"
          required
        />
 
        <TextField
          fullWidth
          name="description"
          label="Description"
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          required
        />
 
        <FormControlLabel
          label="Active"
          labelPlacement="start"
          control={<Checkbox
            name="is_active"
            checked={formData.is_active}
            onChange={handleCheckboxToggle}
            />}
          />
 
        <br></br>
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button onClick={handleReset} style={{ backgroundColor: "White", color: "black" }}>
            Reset
          </Button>
          <Button type="submit" style={{ backgroundColor: "green" }}>Submit</Button>
        </ButtonGroup>
      </form>
 
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
 
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
 
      {/* <Adminsidebar /> */}
    </Box>
  );
};
 
export default AddMessage2;
 