import React, { useState, useEffect } from "react";
import axios from "axios"
 
import { TextField, Button, ButtonGroup, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Box } from '@mui/material';
 
import { Input,IconButton, InputAdornment,useMediaQuery, Snackbar,
  Alert,} from '@mui/material';
 
import { CloudUpload ,Add,Delete} from '@mui/icons-material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";
 
import SidebarContent from 'components/SidebarContent';
 
import FlexBetween from "components/FlexBetween";
 
import Header from "components/Header";
import SidebarCustomers from "components/SidebarCustomers";
 
import InputFileUpload from 'components/ChooseFile';
// import SidebarManage from 'components/SidebarManage';
 
// import Adminsidebar from 'components/adminsidebar';
 
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
 
 
 
const Addcustomer_saz1 = () => {
 
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
  const {cust_id} = useParams();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [imagefile,setImagefile] = useState();
 
  const [formData, setFormData] = useState({
id: "",
name: "",
surname:"",
nick:"",
mac:"",
mail:"",
password:"",
enddate:"",
createdby:"",
billing_id:"",
device_id:"",
expiry_date:"",
is_multiple_session:"",
is_loggedin:"",
status:1,
group_id:"",
created:"",
modified:"",
payment_settings_id:"",
category_password:"",
user_limit:"",
number_of_login:"",
is_deleted:"",
is_trail:1,
is_paid:"",
is_cdn:"",
reseller_id:"",
activation_date:"",
renewal_date:"",
mac:"",
device_code:"",
payment_type:"",
// image: null,
// url: "",
    playlists: [
      {
        playlistname: "",
        url: "",
      },
    ],
 
  });
 
  const handleReset = () => {
    setFormData({
      id: "",
      name: "",
      surname:"",
      nick:"",
      mac:"",
      mail:"",
      password:"",
      enddate:"",
      createdby:"",
      billing_id:"",
      device_id:"",
      expiry_date:"",
      is_multiple_session:"",
      is_loggedin:"",
      status:1,
      group_id:"",
      created:"",
      modified:"",
      payment_settings_id:"",
      category_password:"",
      user_limit:"",
      number_of_login:"",
      is_deleted:"",
      is_trail:1,
      is_paid:"",
      is_cdn:"",
      reseller_id:"",
      activation_date:"",
      renewal_date:"",
      url: "",
      mac:"",
      device_code:"",
      payment_type:"",
      // image:null,
      playlists: [
      {
        playlistname: "",
        url: "",
      },
    ],
          });
  };
 
 
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
 
  const handleChange = (e) => {
 
    const { name, value } = e.target;
 
    setFormData((prevData) => ({ ...prevData, [name]: value }));
 
  };

  //Handling Image Change
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         image: reader.result.split(',')[1], // Extract the base64 string
  //       }));
  //     };
  //   }
  // };
 
  // Callback function to handle date selection
  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      enddate: date,
    }));
  };
 
  const handlePlaylistChange = (index, field, value) => {
    const updatedPlaylists = [...formData.playlists];
    updatedPlaylists[index][field] = value;
    setFormData((prevData) => ({ ...prevData, playlists: updatedPlaylists }));
  };
 
  const handleAddPlaylist = () => {
    setFormData((prevData) => ({
      ...prevData,
      playlists: [...prevData.playlists, { name: "", url: "" }],
    }));
  };
 
  const handleDeletePlaylist = (index) => {
    const updatedPlaylists = [...formData.playlists];
    updatedPlaylists.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, playlists: updatedPlaylists }));
  };

  const handleCheckboxToggle = () => {
    
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      status: prevData.status ? 0 : 1,
    }));
    console.log(formData.status);
  };

  const handleIsTrailCheckboxToggle = () => {
    
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      is_trail: prevData.is_trail ? 0 : 1,
    }));
    console.log(formData.is_trail);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  // const [paymentTypes, setPaymentTypes] = useState([]);
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/customer_saz1/${_id}`)
        .then((response) => {
          const productData1 = response.data;
          // console.log(productData.playlists,productData._doc.name,productData.surname);
          // var playlists = [{playlistname:'hhh', url:'kjkj'}];
          let newdata = productData1.playlists.map((ele) =>{
            return {playlistname:ele.name, url:ele.url}
            });
          const productData = productData1._doc;
          console.log(newdata,'abcd');
          console.log(productData,"payment check");
 
          setFormData({
            id: productData.id,
            name: productData.name,
            surname: productData.surname,
            nick: productData.nick,
            mail: productData.mail,
            password: productData.password,
            enddate: productData.enddate,
            createdby: productData.createdby,
            billing_id: productData.billing_id,
            device_id: productData.device_id,
            device_code:productData.device_code,
            mac:productData.mac,
            is_multiple_session: productData.is_multiple_session,
            is_loggedin: productData.is_loggedin,
            status: productData.status,
            group_id: productData.group_id,
            created: productData.created,
            modified: productData.modified,
            payment_settings_id: productData.payment_settings_id,
            category_password: productData.category_password,
            user_limit: productData.user_limit,
            number_of_login: productData.number_of_login,
            is_deleted: productData.is_deleted,
            is_trail: productData.is_trail,
            is_paid: productData.is_paid,
            is_cdn: productData.is_cdn,
            reseller_id: productData.reseller_id,
            activation_date: productData.activation_date,
            renewal_date: productData.renewal_date,
            playlists: newdata,
            payment_type: productData.payment_type,
          });
        })
        .catch((error) => {
          console.error('Error fetching content data:', error);
        });
 
        // axios
        // .get(`https://masatv.net/backendserver/api/saz_playlist_1/${cust_id}`)
        // .then((response) => {
        //   const productData2 = response.data;
        //   setFormData({
        //     playlists: [
        //       {
        //         playlistname:productData2.name,
        //         url:productData2.url,
        //       },
        //     ],
        //   });
        // })
        // .catch((error) => {
        //   console.error('Error fetching content data:', error);
        // });
    }
  }, [_id]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/customer_saz1/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData) // Use axios.patch for the PATCH request
        .then((response) => {
          setSuccessMessage("Customer Saz1 updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/customer_saz1/post"; // Add
      console.log(formData)
      axios
        .post(apiUrl, formData) // Use axios.post for the POST request
        .then((response) => {
          setSuccessMessage("Customer Saz1 added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
 
    console.log(formData);
  };
 
  const [paymentTypes, setPaymentTypes] = useState([]);
  // Make an API request to fetch payment types data from your server
   useEffect(() => {
     fetch("https://masatv.net/backendserver/api/payment_types/list")
       .then((res) => res.json())
       .then((res) => {
         console.log(res);
         setPaymentTypes(res.data);
       });
   }, []);
 
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/countries/list")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCountries(res.countrydata);
      });
  }, []);
 
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/packages/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setPackages(res.data);
      });
  }, []);
 
  const [resellers, setResellers] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/resellers/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setResellers(res.data);
      });
  }, []);
 
  return (
    <Box  m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "300px"} mt={isSmallScreen ? "70px" : "40px"}>
    <FlexBetween>
    <Header title="Sazpin New Customer Registration" />
 
    <Box></Box>
      </FlexBetween>
      <form onSubmit={handleSubmit}>
        <h3>
          <u>Product Selection</u>
        </h3>
 
          <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">Choose Package</InputLabel>
          <Select
            label="Choose Package"
            name="choose_package"
            value={formData.choose_package}
            onChange={handleChange}
          >
            <MenuItem value="17">(Choose package Type)</MenuItem>
 
            {packages.map((type) => (
            <MenuItem key={type.id} value={type.id}>
            {type.name}
            </MenuItem>
            ))}
            {/* <MenuItem value="farisi">farisi turkish</MenuItem>
            <MenuItem value="6">FRANCE</MenuItem>
            <MenuItem value="8">FRANCE Plus</MenuItem>
            <MenuItem value="4">Full package</MenuItem>
            <MenuItem value="12">Humax Vejo Arab TV</MenuItem>
            <MenuItem value="18">Leo Player</MenuItem>
            <MenuItem value="14">Lite Tv</MenuItem>
            <MenuItem value="1">Sazpin Basic Plan</MenuItem>
            <MenuItem value="11">Sazpin basisc no german</MenuItem>
            <MenuItem value="13">Sazpin New Song</MenuItem>
            <MenuItem value="7">test</MenuItem>
            <MenuItem value="10">TimeShift Package</MenuItem>
            <MenuItem value="3">Turkish Basic Plan</MenuItem> */}
 
            {/* Add more language MenuItems as needed */}
 
            {/* Select2 */}
          </Select>
        </FormControl>
 
 
<FormControl fullWidth variant="outlined" margin="normal">
 
          <InputLabel htmlFor="select_the_category">Reseller</InputLabel>
          <Select
            label="Reseller"
            name="reseller"
            value={formData.reseller}
            onChange={handleChange}
          >
            <MenuItem value="17">(Choose Reseller)</MenuItem>
            {resellers.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
     
        <TextField
  fullWidth
  name="mac"
  label="MacAddress"
  variant="outlined"
  value={formData.mac}
  onChange={(e) => {
    const value = e.target.value;
    // const macValue = value.replaceAll(':', '');
    setFormData({
      ...formData,
      nick: value.replaceAll(':', ''), // Store the value without colons in the nick field
      device_id: value.replaceAll(':', ''),  // Store the value without colons in the device_id field
      mac: value, // Store the original value with colons in the mac field
    });
  }}
  margin="normal"
/>
 
<FormControl fullWidth variant="outlined" margin="normal">

<InputLabel htmlFor="select_the_category">Expiry Date</InputLabel>
<Select
  label="Expiry Date"
  name="expiry_date"
  value={formData.expiry_date}
  onChange={handleChange}
>
  <MenuItem value="6months">6 Months</MenuItem>
  <MenuItem value="1year">1 Year</MenuItem>
  <MenuItem value="3years">3 years</MenuItem>
  <MenuItem value="lifetime">Lifetime</MenuItem>
</Select>
</FormControl>

        <div>
          {formData.playlists.map((playlists, index) => (
            <div key={index} style={{ alignItems: "center" }}>
          <TextField
            fullWidth
            name={`playlistname${index}`}
            label="Playlist Name"
            variant="outlined"
            value={playlists.playlistname}
            onChange={(e) =>
              handlePlaylistChange(index, "playlistname", e.target.value)
            }
            margin="normal"
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <InputAdornment position="end">
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeletePlaylist(index)}
                    // onClick={() => handleRemoveField(index)}
                  >
                    <Delete />
                  </IconButton>
                  </InputAdornment>
                </React.Fragment>
              ),
            }}
          />
 
<TextField
            fullWidth
            name={`url${index}`}
            label="URL"
            variant="outlined"
            value={playlists.url}
            onChange={(e) =>
              handlePlaylistChange(index, "url", e.target.value)
            }
            // onChange={(event) => {
            //   const newFormData = [...formData];
            //   newFormData[index].url = event.target.value;
            //   setFormData(newFormData);
            // }}
            margin="normal"
          />
        </div>
))}
      <IconButton onClick={handleAddPlaylist} color="primary" >
        <Add />
      </IconButton>
    </div>
 

    <FormControlLabel
    label="Active"
    labelPlacement="start"
    control={<Checkbox
            name="status"
            checked={formData.status}
            onChange={handleCheckboxToggle}
          />}
    />
      
      <br />
        <FormControlLabel
    label="Is Trial"
    labelPlacement="start"
    control={<Checkbox
            name="is_trail"
            checked={formData.is_trail}
            onChange={handleIsTrailCheckboxToggle}
          />}
    />

      
    <h3>
          <u>Extra Settings</u>
        </h3>
        <TextField
          fullWidth
          name="cat_pass"
          label="Category Password"
          variant="outlined"
          value={formData.cat_pass}
          onChange={handleChange}
          margin="normal"
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">
            STB's per Household
          </InputLabel>
          <Select
            label="stb's_per_household"
            name="stbs_per_household"
            value={formData.stbs_per_household}
            onChange={handleChange}
          >
            <MenuItem value="One">STB One</MenuItem>
          </Select>
        </FormControl>
 
 
       
        <ButtonGroup variant="contained" aria-label="outlined button group">
        <Button type="reset" onClick={handleReset}>Reset</Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
 
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
 
export default Addcustomer_saz1;