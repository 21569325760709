
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  Snackbar,
  Alert
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Input } from "@mui/material";
 
import { CloudUpload } from "@mui/icons-material";
 
import SidebarContent from "components/SidebarContent";
 
import FlexBetween from "components/FlexBetween";
 
import Header from "components/Header";
 
import InputFileUpload from "components/ChooseFile";
 
// import Adminsidebar from 'components/adminsidebar';
 
const AddUpdates9 = () => {
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [formData, setFormData] = useState({
    id:"",
    version:"",
    url:"",
    description:"",
    products_id:"",
    created_date:"",
  });
 
  const handleReset = () => {
    setFormData({
      id:"",
    version:"",
    url:"",
    description:"",
    products_id:"",
    created_date:"",
          });
  };
 
 
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
 
    setFormData({ ...formData, [name]: value });
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/customer_saz2_app_updates/${_id}`)
        .then((response) => {
          const productData = response.data;
          setFormData({
            version:productData.version,
            url:productData.url,
            description:productData.description,
            // created:productData.created,
            // start_date:productData.start_date,
            // repeat_intervals:productData.repeat_intervals,
            // isactive:productData.isactive,
          });
        })
        .catch((error) => {
          console.error('Error fetching product data:', error);
        });
    }
  }, [_id]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/customer_saz2_app_updates/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData)
        .then((response) => {
          setSuccessMessage("Customer Saz2 App updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/customer_saz2_app_updates/post"; // Add
      axios
        .post(apiUrl, formData)
        .then((response) => {
          setSuccessMessage("Customer Saz2 App Messages added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
    console.log(formData);
  };
 
  const [products, setProducts] = useState([]);
 
  // Make an API request to fetch payment types data from your server
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/customer_saz2_app_updates/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setProducts(res.data);
      });
  }, []);
 
  const [models, setModels] = useState([]);
useEffect(() => {
  fetch("https://masatv.net/backendserver/api/products/list")
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      setModels(res.data);
    });
}, []);
 
  return (
    <Box   m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "350px"} mt={isSmallScreen ? "70px" : "40px"}>
      <FlexBetween>
        <Box></Box>
      </FlexBetween>
 
      <h3>APPUPDATE ADD </h3>
 
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="version"
          label="Firmware Version"
          variant="outlined"
          value={formData.version}
          onChange={handleChange}
          margin="normal"
          required
        />
 
        {/*
 
 
 
         <FormControl>
 
 
 
            <FormControlLabel
 
 
 
            label = " Visible"
 
 
 
              control={
 
 
 
                <Checkbox
 
 
 
                  id="agree"
 
 
 
                  checked={checked}
 
 
 
                  onChange={(e) => setChecked(e.target.checked)}
 
 
 
                />
 
 
 
               
 
 
 
              }
 
 
 
             />
 
 
 
           
 
 
 
          </FormControl> */}
 
        <TextField
          fullWidth
          name="description"
          label="Description"
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          required
        />
 
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="Model">Model</InputLabel>
 
          <Select
            label="Model"
            name="model"
            value={formData.model}
            onChange={handleChange}
            required
          >
            <MenuItem value="17">(Choose Model)</MenuItem>
            {models.map((type) => (
          <MenuItem key={String(type.id) } value={String(type.id)}>
            {type.model}
            </MenuItem>
          ))}
            {/* Add more language options as needed */}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          name="url"
          label="Firmware"
          variant="outlined"
          value={formData.url}
          onChange={handleChange}
          margin="normal"
          required
        />
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button onClick={handleReset} style={{ backgroundColor: "White", color: "black" }}>
            Reset
          </Button>
          <Button type="submit" style={{ backgroundColor: "green" }}>Submit</Button>
        </ButtonGroup>
      </form>
     
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
 
      {/* <Adminsidebar /> */}
    </Box>
  );
};
 
export default AddUpdates9;
 