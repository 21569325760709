import { CSVLink } from "react-csv";
import React from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Grid } from "@mui/material";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  Delete,
  Edit,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu as MenuIcon,
  Container,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import BreakdownChart from "components/BreakdownChart";
// import OverviewChart from "components/OverviewChart";
// import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import Navbar from "components/Navbar";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ButtonGroup, MenuItem, Menu } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ImFilter } from "react-icons/im";
import { TbArrowsSort } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const Customer_Saz3 = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMediumScreens = useMediaQuery("(min-width: 1800px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  // const { data, isLoading } = useGetDashboardQuery();

  const [filteredCategories, setFilteredCategories] = useState([]);
const [searchTerm, setSearchTerm] = useState("");


  const [allcustomer_saz3, setAllcustomer_saz3] = useState([]);
  const fetchData = () => {
    fetch("https://masatv.net/backendserver/api/customer_saz3/get")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setAllcustomer_saz3(res.data);
        const filteredRows = res.data.filter((row) =>
        (
          row.mac?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.device_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.is_trail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.enddate?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.created?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.customer_id?.toLowerCase().includes(searchTerm.toLowerCase()) 
        )
      );

      setFilteredCategories(filteredRows);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
  console.log(allcustomer_saz3);

  useEffect(() => {
    fetchData(); // Fetch initial data when the component mounts
  }, [searchTerm]);

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [dataGridWidth, setDataGridWidth] = useState(0);

  const openAddDialog = () => {
    setAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
  };

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const openDeleteDialog = (_id) => {
    setDeleteDialogOpen(true);
    setDeleteItemId(_id);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };
  const [isSidebarContentOpen, setIsSidebarContentOpen] = useState(true);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.7,
    },
    // {
    //   field: "symbol",
    //   headerName: "Symbol",
    //   flex: 1,
    //   renderCell: (params) => {
    //     const _id = params.row._id; // Assuming 'id' is a unique identifier for the row
    //     return (
    //       <div>
    //         <IconButton
    //           // onClick={() => handleDeleteAction(params.row._id)}
    //           onClick={openAddDialog}
    //           aria-label="plus"
    //           color="primary"
    //           // sx={{mr:"-10px"}}
    //         >
    //           <AddCircleIcon />
    //         </IconButton>
    //         <IconButton
    //           // onClick={() => handleDeleteAction(params.row._id)}
    //           aria-label="dropdown"
    //           color="primary"
    //         >
    //           <ArrowDropDownIcon />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "mac",
      headerName: "MAC",
      flex: 1.1,
      exportable: true,
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      flex: 1.1,
    },
    {
      field: "Module Name",
      headerName: "Module Name",
      flex: 1.1,
    },
    {
      field: "device_code",
      headerName: "DeviceType",
      flex: 1,
      // renderCell: (params) => params.value.length,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.7,
      // renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "is_trail",
      headerName: "isTrail",
      flex: 0.7,
      // renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "status",
      headerName: "Active/InActive",
      flex: 1,
      // renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "enddate",
      headerName: "Expiry Date",
      flex: 1,
      // renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "created",
      headerName: "Created Date",
      flex: 1,
      // renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      // renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
      renderCell: (params) => {
        // const id = params.row.id; // Assuming 'id' is a unique identifier for the row
        const handleEditAction = (_id) => {
          navigate(`/Customers/addcustomer_saz3/${params.row._id}`);
          // Ikkada Edit Action Logic Raasko
          console.log(`Edit action for ID ${_id}`);
          // aah Edit Logic ikkada Add chesko
        };
        const handleDeleteAction = (_id) => {
          // Open the delete confirmation dialog
          openDeleteDialog(_id);
        };
        const handleDeleteConfirmation = () => {
          // Assuming you have an API endpoint for deleting by ID
          axios
            .delete(
              `https://masatv.net/backendserver/api/customer_saz3/delete/${deleteItemId}`
            )
            .then((response) => {
              console.log(`Item with ID ${deleteItemId} deleted successfully.`);
              closeDeleteDialog(); // Close the dialog
              fetchData();
              // You might want to refresh your data after a successful delete
            })
            .catch((error) => {
              console.error(
                `Error deleting item with ID ${deleteItemId}:`,
                error
              );
            });
        };

        // const handleEditAction = () => {
        //   // Ikkada Edit Action Logic Raasko
        //   console.log(`Edit action for ID `);
        //   // aah Edit Logic ikkada Add chesko
        // };
        // const handleDeleteAction = () => {
        //   // Ikkada Delete Action Logic Raasko
        //   console.log(`Delete action for ID `);
        //   // aah Delete Logic ikkada Add chesko
        // }

        return (
          <div>
            <IconButton
              onClick={handleEditAction}
              aria-label="Edit"
              color="primary"
            >
              <Edit />
            </IconButton>

            <text>|</text>

            <IconButton
              onClick={() => handleDeleteAction(params.row._id)}
              aria-label="Delete"
              color="secondary"
            >
              <Delete />
            </IconButton>
            <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this item?
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    },
  ];

  //Content of a pop up Dialogue box
  // const AddDialogContent = (
  //   <div>
  //     <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
  //       <Tabs
  //         value={value}
  //         onChange={handleChange}
  //         aria-label="basic tabs example"
  //       >
  //         <Tab label="Customer Subscription" {...a11yProps(0)} />
  //         <Tab label="Customer Device" {...a11yProps(1)} />
  //       </Tabs>
  //     </Box>
  //     <CustomTabPanel value={value} index={0}>
  //       <Box sx={{ display: "flex" }}>
  //         <Button
  //           variant="contained"
  //           sx={{
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "flex-start",
  //             ml: "5px",
  //             height: "30px",
  //             maxwidth: "100px",
  //           }}
  //         >
  //           <AddIcon sx={{ ml: "-5px" }} /> Add New
  //         </Button>
  //       </Box>
  //     </CustomTabPanel>
  //     <CustomTabPanel value={value} index={1}>
  //       Table 2 Content
  //     </CustomTabPanel>
  //   </div>
  // );

  return (
    // <Box
    //   m={isSmallScreen ? "1rem" : "1.5rem 3.5rem"}
    //   ml={isSmallScreen ? "10px" : "20px"}
    // >

    <Grid
      m={isSmallScreen ? "1rem" : "1.5rem 3.5rem"}
      ml={isSmallScreen ? "10px" : "300px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      <FlexBetween>{/* <Header title="Customer_Saz2" /> */}</FlexBetween>
      <Navbar />

      {/* Below Grid Contains Statbox and DataGrid */}
      <Grid flexDirection={isSmallScreen ? "column" : "row"}>
        <Box
          mt={isSmallScreen ? "10px" : "50px"}
          // mt="50px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="250px"
          gap={isSmallScreen ? "10px" : "0px"}
          // gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="Customer_Saz3"
            searchtab={
              <div className="content">
                <div
                  id="data_table_wrapper"
                  className="dataTables_wrapper"
                  role="grid"
                >
                  <div className="dataTables_filter" id="data_table_filter">
                  <label>
                      Search : &nbsp;&nbsp;
                      <input
                        type="text"
                        aria-controls="data_table"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            }
            sx={{
              display: "grid",
              gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr", // Adjust columns for small screens
              gap: "10px",
              // Add other styles as needed
            }}
            //   icon={
            //     <Email
            //       sx={{ color: theme.palette.secondary[100], fontSize: "26px" }}
            //     />
            //   }
          />
          {/* Table */}
          <Grid sx={{ height: 450, mt: isSmallScreen ? "-20px" : "-30px" }}>
            <Box
              backgroundColor={theme.palette.background.alt}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
                borderRadius: "0.55rem",
                height: "60px",
                mt: "-160px",
                overflowX: "auto",
              }}
            >
              <Button
                onClick={() => navigate("/Customers/addcustomer_saz3")}
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  ml: "5px",
                  height: "30px",
                  maxwidth: "100px",
                }}
              >
                <AddIcon sx={{ ml: "-5px" }} /> Add New
              </Button>
              <CSVLink
                  data={allcustomer_saz3}
                  filename="customer_saz3.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "30px",
                      maxWidth: "100px",
                    }}
                  >
                    <IosShareIcon sx={{ ml: "-5px", fontSize: "17px" }} />
                    Export
                  </Button>
                </CSVLink>
              
            </Box>

            <DataGrid
              ref={(grid) => grid && setDataGridWidth(grid.clientWidth)}
              sx={{ mt: isSmallScreen ? "10px" : "10px" }}
              getRowId={(row) => row._id}
              // checkboxSelection
              rows={filteredCategories}
              columns={columns}
            />

            {/* <Dialog open={isAddDialogOpen} onClose={closeAddDialog}>
              <DialogTitle>Title</DialogTitle>
              <DialogContent sx={{ width: dataGridWidth }}>
                {AddDialogContent}
              </DialogContent>
              <DialogActions>
                <Button onClick={closeAddDialog} color="primary">
                  Cancel
                </Button>
                Add your logic for adding a new item here
              </DialogActions>
            </Dialog> */}
          </Grid>
        </Box>
      </Grid>

      <CustomColumnMenu />
      <FlexBetween>
        <IconButton
          onClick={() => setIsSidebarContentOpen(!isSidebarContentOpen)}
        >
          <MenuIcon />
        </IconButton>
      </FlexBetween>
    </Grid>
  );
};

export default Customer_Saz3;
