import { CSVLink } from "react-csv";
import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";

import FlexBetween from "components/FlexBetween";

import Header from "components/Header";

import CustomColumnMenu from "components/DataGridCustomColumnMenu";

import SidebarContent from "components/SidebarContent";

import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  Search,
  Edit,
  Delete,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

// import BreakdownChart from "components/BreakdownChart";

// import OverviewChart from "components/OverviewChart";

// import { useGetDashboardQuery } from "state/api";

import StatBox from "components/StatBox";
import Navbar from "components/Navbar";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ButtonGroup, MenuItem, Menu } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ImFilter } from "react-icons/im";
import { TbArrowsSort } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";

const Songslist = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery(
    "(min-width: 400px, max-width: 1280px)"
  );

const [filteredCategories, setFilteredCategories] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  // const { data, isLoading } = useGetDashboardQuery();

  const [songslist, setSongslist] = useState([]);

  const fetchData = () => {
    fetch("https://masatv.net/backendserver/api/songs/find")
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        setSongslist(res.data);

        const filteredRows = res.data.filter(
          (row) =>
            row.streamName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.visible?.toLowerCase().includes(searchTerm.toLowerCase()) || // Add filter for "Active Status"
            row.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||// Add filter for "Created Date"
            row.created?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredCategories(filteredRows);
      });
  };
  // console.log(songslist);
  useEffect(() => {
    fetchData(); // Fetch initial data when the component mounts
  }, [searchTerm]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const openDeleteDialog = (_id) => {
    setDeleteDialogOpen(true);
    setDeleteItemId(_id);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };

  const columns = [
    {
      field: "image",

      headerName: "Image",

      flex: 1,
    },

    {
      field: "streamName",

      headerName: "Name",

      flex: 1,
    },

    {
      field: "category",

      headerName: "Category",

      flex: 1,
    },

    {
      field: "created",

      headerName: "Date Added",

      flex: 1,
    },

    {
      field: "visible",

      headerName: "Active Status",

      flex: 0.5,

      sortable: false,

      // renderCell: (params) => params.value.length,
    },

    {
      field: "actions",

      headerName: "Actions",

      flex: 1,

      renderCell: (params) => {
        const _id = params.row._id; // Assuming 'id' is a unique identifier for the row

        const handleEditAction = (_id) => {
          navigate(`/AddSongs/${params.row._id}`);
          // Ikkada Edit Action Logic Raasko
          console.log(`Edit action for ID ${_id}`);
          // aah Edit Logic ikkada Add chesko
        };
        const handleDeleteAction = (_id) => {
          // Open the delete confirmation dialog
          openDeleteDialog(_id);
        };

        const handleDeleteConfirmation = () => {
          // Assuming you have an API endpoint for deleting by ID
          axios
            .delete(`https://masatv.net/backendserver/api/songs/delete/${deleteItemId}`)
            .then((response) => {
              console.log(`Item with ID ${deleteItemId} deleted successfully.`);
              closeDeleteDialog(); // Close the dialog
              fetchData();
              // You might want to refresh your data after a successful delete
            })
            .catch((error) => {
              console.error(
                `Error deleting item with ID ${deleteItemId}:`,
                error
              );
            });
        };

        return (
          <div>
            <IconButton
              onClick={handleEditAction}
              aria-label="Edit"
              color="primary"
            >
              <Edit />
            </IconButton>
            <text>|</text>

            <IconButton
              onClick={() => handleDeleteAction(params.row._id)}
              aria-label="Delete"
              color="secondary"
            >
              <Delete />
            </IconButton>
            <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this item?
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    },
  ];

  return (
    // <Box m="1.5rem 2.5rem">

    //   <FlexBetween>

    //     <Header title="Movies" subtitle="Welcome to your Movies" />

    //     <Box>

    //       <Button

    //         sx={{

    //           backgroundColor: theme.palette.secondary.light,

    //           color: theme.palette.background.alt,

    //           fontSize: "14px",

    //           fontWeight: "bold",

    //           padding: "10px 20px",

    //         }}

    //       >

    //         <DownloadOutlined sx={{ mr: "10px" }} />

    //         Download Reports

    //       </Button>

    //     </Box>

    //   </FlexBetween>

    // <Box m="1.5rem 2.5rem" ml="250px">
    <Box
      m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"}
      ml={isSmallScreen ? "10px" : "300px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      <FlexBetween>
        {/* <Header title="ADD MOVIE" /> */}
        <Box></Box>
      </FlexBetween>

      {/* Below Grid Contains Statbox and DataGrid */}
      <Grid flexDirection={isSmallScreen ? "column" : "row"}>
        <Box
          mt={isSmallScreen ? "10px" : "50px"}
          // mt="50px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="250px"
          gap={isSmallScreen ? "10px" : "0px"}
          // gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}

          <StatBox
            title="SONG LISTING"
            // value={data && data.totalCustomers}
            // increase="+14%"
            // description="Since last month"

            icon={
              <Search
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
            searchtab={
              <div className="content">
                <div
                  id="data_table_wrapper"
                  className="dataTables_wrapper"
                  role="grid"
                >
                  <div className="dataTables_filter" id="data_table_filter">
                  <label>
                      Search : &nbsp;&nbsp;
                      <input
                        type="text"
                        aria-controls="data_table"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            }
          />
          {/* Table */}
          <Grid sx={{ height: 450, mt: isSmallScreen ? "-20px" : "-30px" }}>
            <Box
              backgroundColor={theme.palette.background.alt}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
                borderRadius: "0.55rem",
                height: "60px",
                mt: "-160px",
                overflowX: "auto",
              }}
            >
              <Button
                onClick={() => navigate("/AddSongs")}
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  ml: "5px",
                  height: "30px",
                  maxwidth: "100px",
                }}
              >
                <AddIcon sx={{ ml: "-5px" }} /> Add New
              </Button>
              <CSVLink
                  data={songslist}
                  filename="songslist.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "30px",
                      maxWidth: "100px",
                    }}
                  >
                    <IosShareIcon sx={{ ml: "-5px", fontSize: "17px" }} />
                    Export
                  </Button>
                </CSVLink>
              
            </Box>

            <DataGrid
              sx={{ mt: isSmallScreen ? "10px" : "10px" }}
              getRowId={(row) => row._id}
              // checkboxSelection
              rows={filteredCategories}
              columns={columns}
            />
          </Grid>
        </Box>
      </Grid>

      {/* ROW 2 */}

      <Box
        gridColumn="span 8"
        gridRow="span 3"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",

            borderRadius: "5rem",
          },

          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,

            color: theme.palette.secondary[100],

            borderBottom: "none",
          },

          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },

          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,

            color: theme.palette.secondary[100],

            borderTop: "none",
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      ></Box>

      {/* <SidebarContent /> */}

      <CustomColumnMenu />
    </Box>
  );
};

export default Songslist;
