//DropDownBar

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import PeopleIcon from '@mui/icons-material/People';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import FeaturedPlayListTwoToneIcon from '@mui/icons-material/FeaturedPlayListTwoTone';
import QueueMusicTwoToneIcon from '@mui/icons-material/QueueMusicTwoTone';
import {
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  CssBaseline,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ThemeProvider,
  useTheme,
  IconButton,


  useMediaQuery,
} from "@mui/material";
import {
  PlaylistAddCircle,
  LiveTv,
  ListAlt,
  Inbox,
  ExpandLess,
  ExpandMore,
  StarBorder,
  Sort,
  Theaters,
  AddCircleSharp,
  AutoAwesomeMotionSharp,
  RadioTwoTone,
  FastRewind,
  TheatersTwoTone,
  MusicNote,
  PlaylistAdd,
  LibraryMusic,
  OndemandVideo,
  Layers,
  ChevronLeft,
  ChevronRight,

  Menu as MenuIcon,
  SortByAlpha,
  NotificationAddOutlined,
} from "@mui/icons-material";
import FlexBetween from "./FlexBetween";

function SidebarCustomers( drawerWidth) {
  // Define state and click handlers for each dropdown
  const [openDropdown1, setOpenDropdown1] = useState(false);
  const [openDropdown2, setOpenDropdown2] = useState(false);
  const [openDropdown3, setOpenDropdown3] = useState(false);
  const [openDropdown4, setOpenDropdown4] = useState(false);
  const [openDropdown5, setOpenDropdown5] = useState(false);
  const [openDropdown6, setOpenDropdown6] = useState(false);
  const [openDropdown7, setOpenDropdown7] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  // Define click handlers for each dropdown
  const handleDropdown1Click = () => {
    setOpenDropdown1(!openDropdown1);
  };
  const handleDropdown2Click = () => {
    setOpenDropdown2(!openDropdown2);
  };
  const handleDropdown3Click = () => {
    setOpenDropdown3(!openDropdown3);
  };

  const handleDropdown4Click = () => {
    setOpenDropdown4(!openDropdown4);
  };
  const handleDropdown5Click = () => {
    setOpenDropdown5(!openDropdown5);
  };
  const handleDropdown6Click = () => {
    setOpenDropdown6(!openDropdown6);
  };

  const handleDropdown7Click = () => {
    setOpenDropdown7(!openDropdown7);
  };


  return (
    <ThemeProvider theme={theme}>
      <Drawer
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        anchor="left"
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            color: theme.palette.secondary[200],
            backgroundColor: theme.palette.background.alt,
            boxSizing: "border-box",
          },
        }}
      >
        {/* <h1>
          <center>Customers</center>
        </h1> */}
        <img style={{marginLeft:"30px",marginBottom:"10px",marginTop:"10px",height:"140px" , width:"140px"}}src="/assets/Masa_Logo.png" alt= "no image"></img>
        <List>
          {/* Dropdown 1 */}
          <ListItem button onClick={handleDropdown1Click}>
            <ListItemIcon>
            <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="ADMINISTRATOR" />
            {openDropdown1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
          <ListItem button component={Link} to="/ALLADMINISTRATOR">
            <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
                <ListItemText primary="ALLADMINISTRATOR" />
              </ListItem>
              <ListItem button component={Link} to="/ADDADMINISTRATOR">
                <ListItemIcon>
                <GroupAddOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="ADDADMINISTRATOR" />
              </ListItem>
            </List>
          </Collapse>

      
          {/* Dropdown 5 */}
         <ListItem button onClick={handleDropdown5Click}>
            <ListItemIcon>
            <QueueMusicTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Demo_saz_playlist_1" />
            {openDropdown5 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/Demo_saz_playlist_1/All_Playlist">
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary="All Playlist" />
              </ListItem>
              <ListItem button component={Link} to="/Demo_saz_playlist_1/Add_Playlist">
                <ListItemIcon>
                <AddCircleSharp />
                </ListItemIcon>
                <ListItemText primary="Add Playlist" />
              </ListItem>
            </List>
          </Collapse>

          {/* Dropdown 6 */}
         <ListItem button onClick={handleDropdown6Click}>
            <ListItemIcon>
            <QueueMusicTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Demo_saz_playlist_2" />
            {openDropdown6 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown6} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/Demo_saz_playlist_2/All_Playlist">
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary="All Playlist" />
              </ListItem>
              <ListItem button component={Link} to="/Demo_saz_playlist_2/Add_Playlist">
                <ListItemIcon>
                <AddCircleSharp />
                </ListItemIcon>
                <ListItemText primary="Add Playlist" />
              </ListItem>
            </List>
          </Collapse>

          {/* Dropdown 7 */}
         <ListItem button onClick={handleDropdown7Click}>
            <ListItemIcon>
            <QueueMusicTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Demo_saz_playlist_3" />
            {openDropdown7 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown7} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/Demo_saz_playlist_3/All_Playlist">
                <ListItemIcon>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText primary="All Playlist" />
              </ListItem>
              <ListItem button component={Link} to="/Demo_saz_playlist_3/Add_Playlist">
                <ListItemIcon>
                <AddCircleSharp />
                </ListItemIcon>
                <ListItemText primary="Add Playlist" />
              </ListItem>
            </List>
          </Collapse>


          

        </List>
      </Drawer>
     
               {isSmallScreen && (
      <IconButton
        color="inherit"
        aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        sx={{
          position: 'fixed',
          top: '1%',
          left: '1%',
          transform: 'translateY(50%)',
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        {isSidebarOpen ? <ChevronLeft sx={{bgcolor:"#bda140",
          color:"black",borderRadius:"3rem", fontSize:"2rem"}} /> : <ChevronRight sx={{bgcolor:"black",
          color:"#bda140", borderRadius:"3rem", fontSize:"2rem"}} />}
      </IconButton>
      )}
 
 
 
    </ThemeProvider>

  );
}

export default SidebarCustomers;





