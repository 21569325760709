
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  TextField,
  Button,
  Box,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import Navbar from "components/Navbar";
import StatBox from "components/StatBox";
import FlexBetween from "components/FlexBetween";
 
const MovieSortableItem = ({ id, label, index, moveItem }) => {
  const [, ref] = useDrag({
    type: "MOVIE",
    item: { id, index },
  });
 
  const [, drop] = useDrop({
    accept: "MOVIE",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
 
  return (
    // <div ref={(node) => ref(drop(node))}>
      <div ref={(node) => ref(drop(node))} style={{ cursor: "move" }}>
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        margin="normal"
        disabled
      />
    </div>
  );
};
const MovieSortableList = () => {
  const [checked, setChecked] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
 
  const [formData, setFormData] = useState({
    TheChroniclesofNarnia1: "",
    ThePunisher2004: "",
    BatmanBegins2005: "",
    Theisland2005: "",
    SAW2: "",
  });
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };
 
  const moveItem = (fromIndex, toIndex) => {
    setFormData((prevFormData) => {
      const formDataArray = Object.entries(prevFormData);
      const movedItem = formDataArray[fromIndex];
 
      const updatedFormDataArray = update(formDataArray, {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, movedItem],
        ],
      });
 
      const updatedFormData = Object.fromEntries(updatedFormDataArray);
 
      return updatedFormData;
    });
  };
 
 
  const items = Object.entries(formData).map(([key, value], index) => (
    <MovieSortableItem
      key={key}
      id={key}
      label={key}
      index={index}
      moveItem={moveItem}
    />
  ));
 
 
  return (
    <DndProvider backend={HTML5Backend}>
    <Box  m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "350px"} mt={isSmallScreen ? "70px" : "40px"}>
 
      <FlexBetween>
        {/* <Header title="Drag and Drop to change the position of streams" /> */}
 
        <Box></Box>
      </FlexBetween>
      <StatBox
        title="Drag and Drop to change the position of stream"
        // value={data && data.totalCustomers}
        // increase="+14%"
        // description="Since last month"
      />
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="Movie_category">VOD Category</InputLabel>
          <Select
            label="Movie Category"
            name="Movie_category"
            value={formData.song_category}
            onChange={handleChange}
          >
            <MenuItem value="arabic">Arabic</MenuItem>
            <MenuItem value="pop">Pop</MenuItem>
 
            {/* Add more language options as needed */}
          </Select>
        </FormControl>
        {items}
      </form>
      {/* <SidebarContent /> */}
      <Navbar />
    </Box>
    </DndProvider>
  );
};
 
export default MovieSortableList;