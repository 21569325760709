
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import Joi from "joi";
import axios from "axios";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  Alert,
  Snackbar,
  Avatar
} from "@mui/material";
 
import Input from '@mui/material/Input';
import Sidebar from "screens/content";
 
import { ContentCopy } from "@mui/icons-material";
 
import FlexBetween from "components/FlexBetween";
 
import Header from "components/Header";
 
import SidebarContent from "components/SidebarContent";
 
import InputFileUpload from "components/ChooseFile";
 
const AddRadio = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
 
 
 
  // const [validationError, setValidationError] = useState(null);
 
  // const validationSchema = Joi.object(
  //   {
  //   modified: Joi.string().allow('').allow(null),
  //   streamName: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("streamName"),
  //   visible: Joi.allow('').allow(null),
  //   select_channel_image: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("select_channel_image"),
  //   title: Joi.string().allow('').allow(null),
  //   id: Joi.string().allow('').allow(null),
  //   kameraUrl: Joi.string().allow('').allow(null),
  //   kameraDurum: Joi.string().allow('').allow(null),
  //   kameraImage: Joi.string().allow('').allow(null),
  //   created: Joi.string().allow('').allow(null),
  //   category_id: Joi.string().allow('').allow(null),
  //   position: Joi.string().allow('').allow(null),
  //   kameraUrl_secondary: Joi.string().allow('').allow(null),
  //   category: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('description'),
  // });
 
 
  const [formData, setFormData] = useState({
   
    id: "",
    kameraUrl: "",
    streamName: "",
    kameraDurum: "",
    kameraImage: "",
    category_id: "",
    created: "",
    position: "",
    kameraUrl_secondary: "",
    modified: "",
    visible: "1",
    title:"",
    category:"",
  });
 
  const handleReset = () => {
    setFormData({
     
      id: "",
      kameraUrl: "",
      streamName: "",
      kameraDurum: "",
      kameraImage: "",
      category_id: "",
      created: "",
      position: "",
      kameraUrl_secondary: "",
      modified: "",
      visible: "1",
      title:"",
      category:"",
    });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submittedImage, setSubmittedImage] = useState(null);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
 
    setFormData({ ...formData, [name]: value });
  };
 
      //Handling Image Change
      const handleImageChange = (e) => {
        const file = e.target.files[0];
   
        if (file) {
          const reader = new FileReader();
   
          reader.onload = () => {
            if (reader.result) {
              setFormData((prevData) => ({
                ...prevData,
                kameraImage: reader.result.split(',')[1], // Store as base64-encoded string
              }));
            }
          };
   
          reader.readAsDataURL(file);
        }
      };
   
      // Function to decode Base64 image and display it
      const renderBase64Image = () => {
        if (formData.kameraImage) {
          return (
            <Avatar
              alt="Image"
              src={`data:image/png;base64,${formData.kameraImage}`}
              sx={{ width: 100, height: 100 }}
            />
          );
        }
        return null;
      };
 
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      visible: prevData.visible ? 0 : 1,
    }));
    console.log(formData.visible);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/livetv_audios/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.kameraImage) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                id:productData.id,
                kameraUrl:productData.kameraUrl,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:base64Image,
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                modified:productData.modified,
                // visible: "1",
                title:productData.title,
                category:productData.category,
                // image: base64Image,
              });
            };
            img.src = `data:image/png;base64,${productData.kameraImage}`;
          } else {
            setFormData({
              id:productData.id,
                kameraUrl:productData.kameraUrl,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:"",// Set to empty string if there is no image
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                modified:productData.modified,
                // visible: "1",
                title:productData.title,
                category:productData.category,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // const validationResult = validationSchema.validate(formData, {
    //   abortEarly: false,
    // });
 
    // if (validationResult.error) {
    //   setValidationError(validationResult.error.details);
    //   return ;
    // }
 
    // setValidationError(null);
 
    console.log(formData);
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/livetv_audios/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData) // Use axios.patch for the PATCH request
        .then((response) => {
          setSuccessMessage("Radios updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/livetv_audios/post"; // Add
      axios
        .post(apiUrl, formData) // Use axios.post for the POST request
        .then((response) => {
          setSuccessMessage("Radios added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const [audio_categorieslist, setaudio_categorieslist] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/livetv_audios/find")
      .then((response) => response.json())
      .then((json) => setaudio_categorieslist(json.data));
  }, []);
  console.log(audio_categorieslist);
 
  return (
    // <Box m="1.5rem 2.5rem" ml="250px">
    <Box
      m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"}
      ml={isSmallScreen ? "10px" : "300px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      <FlexBetween>
        <Header title="ADD AUDIO STREAM" />
 
        <Box></Box>
      </FlexBetween>
 
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="streamName"
          label="Title"
          variant="outlined"
          value={formData.streamName}
          onChange={handleChange}
          margin="normal"
        />
       
 
<FormControlLabel
    label="Visible"
    labelPlacement="start"
    control={<Checkbox
            name="visible"
            checked={formData.visible}
            onChange={handleCheckboxToggle}
          />}
    />
 
        <TextField
          fullWidth
          name="select_channel_image"
          label="Enter Stream URL"
          placeholder="stream for webtv-primary"
          variant="outlined"
          value={formData.select_channel_image}
          onChange={handleChange}
          margin="normal"
        />
 
          Image:
          <FormControl fullWidth margin="normal">
            <Input id="image-upload" type="file" onChange={handleImageChange} />
          </FormControl>
          {renderBase64Image()}
 
     
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="category">Category</InputLabel>
          <Select
            label="Category"
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <MenuItem>(Choose Category)</MenuItem>
            {audio_categorieslist.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.streamName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
 
        {/* {validationError && (
          <div style={{ color: 'red' }}>
            {validationError.map((error) => (
              <div>{error.message}</div>
            ))}
            </div>
      )} */}
 
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button type="reset" onClick={handleReset}>
            Reset
          </Button>
 
          <Button type="submit">Submit</Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
      {/* <SidebarContent /> */}
    </Box>
  );
};
 
export default AddRadio;
 