import { CSVLink } from "react-csv";
import { Grid } from "@mui/material";
import React from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import SidebarContent from "components/SidebarContent";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
 
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  Delete,
  Edit,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  IconButton,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  openDe,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import BreakdownChart from "components/BreakdownChart";
// import OverviewChart from "components/OverviewChart";
// import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import Navbar from "components/Navbar";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ButtonGroup, MenuItem, Menu } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ImFilter } from "react-icons/im";
import { TbArrowsSort } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SidebarAdmin from "components/SidebarAdmin";
 
const Demoplaylist1 = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const isNonMediumScreens = useMediaQuery(
    "(min-width: 400px, max-width: 1280px)"
  );
 
  const [demoplayllist2, setdemoplayllist2] = useState([]);
  const fetchData = () => {
    fetch("https://masatv.net/backendserver/api/demo_saz_playlist_1/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setdemoplayllist2(res.data);
        const filteredRows = res.data.filter((row) =>
        (
          row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.url?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.expire_data?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.created_date?.toLowerCase().includes(searchTerm.toLowerCase())||
          row.status?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
 
      setFilteredCategories(filteredRows);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      });
  };
 
  useEffect(() => {
    fetchData(); // Fetch initial data when the component mounts
  }, [searchTerm]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
 
  const openDeleteDialog = (_id) => {
    setDeleteDialogOpen(true);
    setDeleteItemId(_id);
  };
 
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };
 
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "url",
      headerName: "URL",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "created_date",
      headerName: "Created_date",
      flex: 1,
    },
    {
      field: "expire_data",
      headerName: "Expired date",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Active/Inactive",
      flex: 0.5,
      sortable: false,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        const _id = params.row._id; // Assuming 'id' is a unique identifier for the row
 
        const handleEditAction = (_id) => {
          navigate(`/Demo_saz_playlist_1/Add_Playlist/${params.row._id}`);
          // Ikkada Edit Action Logic Raasko
          console.log(`Edit action for ID ${_id}`);
          // aah Edit Logic ikkada Add chesko
        };
        const handleDeleteAction = (_id) => {
          // Open the delete confirmation dialog
          openDeleteDialog(_id);
        };
 
        const handleDeleteConfirmation = () => {
          // Assuming you have an API endpoint for deleting by ID
          axios
            .delete(
              `https://masatv.net/backendserver/api/demo_saz_playlist_1/delete/${deleteItemId}`
            )
            .then((response) => {
              console.log(`Item with ID ${deleteItemId} deleted successfully.`);
              closeDeleteDialog(); // Close the dialog
              fetchData();
              // You might want to refresh your data after a successful delete
            })
            .catch((error) => {
              console.error(
                `Error deleting item with ID ${deleteItemId}:`,
                error
              );
            });
        };
 
        return (
          <div>
            <IconButton
              onClick={handleEditAction}
              aria-label="Edit"
              color="primary"
            >
              <Edit />
            </IconButton>
            <text>|</text>
 
            <IconButton
              onClick={() => handleDeleteAction(params.row._id)}
              aria-label="Delete"
              color="secondary"
            >
              <Delete />
            </IconButton>
            <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this item?
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    },
  ];
  // const [timeshiftlist, settimeshiftlistlist] = useState([]);
  // useEffect(() => {
  //   fetch("https://masatv.net/backendserver/api/videolives/get")
  //     .then((response) => response.json())
  //     .then((json) => settimeshiftlistlist(json.data));
  // }, []);
  // console.log(timeshiftlist);
  return (
    // <Box m="1.5rem 2.5rem">
    //   <FlexBetween>
    //     <Header title="Movies" subtitle="Welcome to your Movies" />
 
    //     <Box>
    //       <Button
    //         sx={{
    //           backgroundColor: theme.palette.secondary.light,
    //           color: theme.palette.background.alt,
    //           fontSize: "14px",
    //           fontWeight: "bold",
    //           padding: "10px 20px",
    //         }}
    //       >
    //         <DownloadOutlined sx={{ mr: "10px" }} />
    //         Download Reports
    //       </Button>
    //     </Box>
    //   </FlexBetween>
    <Box
      m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"}
      ml={isSmallScreen ? "10px" : "300px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      <FlexBetween>
        <Box></Box>
      </FlexBetween>
 
      {/* Below Grid Contains Statbox and DataGrid */}
      <Grid flexDirection={isSmallScreen ? "column" : "row"}>
        <Box
          mt={isSmallScreen ? "10px" : "50px"}
          // mt="50px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="250px"
          gap={isSmallScreen ? "10px" : "0px"}
          // gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}
          <StatBox
            title="ALL PLAYLIST"
            // value={data && data.totalCustomers}
            // increase="+14%"
            // description="Since last month"
            searchtab={
              <div className="content">
                <div
                  id="data_table_wrapper"
                  className="dataTables_wrapper"
                  role="grid"
                >
                  <div className="dataTables_filter" id="data_table_filter">
                  <label>
                      Search : &nbsp;&nbsp;
                      <input
                        type="text"
                        aria-controls="data_table"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            }
          />
 
          {/* Table */}
          <Grid sx={{ height: 450, mt: isSmallScreen ? "-20px" : "-30px" }}>
            <Box
              backgroundColor={theme.palette.background.alt}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
                borderRadius: "0.55rem",
                height: "60px",
                mt: "-160px",
                overflowX: "auto",
              }}
            >
              <Button
                onClick={() => navigate("/Demo_saz_playlist_1/Add_Playlist")}
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  ml: "5px",
                  height: "30px",
                  maxwidth: "100px",
                }}
              >
                <AddIcon sx={{ ml: "-5px" }} /> Add New
              </Button>
              <CSVLink
                  data={demoplayllist2}
                  filename="demoplayllist2.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "30px",
                      maxWidth: "100px",
                    }}
                  >
                    <IosShareIcon sx={{ ml: "-5px", fontSize: "17px" }} />
                    Export
                  </Button>
                </CSVLink>
              {/* <Button onClick={()=>fetchData()} variant="contained" sx={{height:"30px"}}><RefreshIcon sx={{ml:"-5px"}}/> Refresh</Button> */}
              
            </Box>
 
            <DataGrid
              sx={{ mt: isSmallScreen ? "10px" : "10px" }}
              getRowId={(row) => row._id}
              // checkboxSelection
              rows={filteredCategories}
              columns={columns}
            />
          </Grid>
        </Box>
      </Grid>
 
      {/* ROW 2 */}
      <Box
        gridColumn="span 8"
        gridRow="span 3"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      ></Box>
 
      <CustomColumnMenu />
    </Box>
  );
};
 
export default Demoplaylist1;
 