import { CSVLink } from "react-csv";
import { Grid } from "@mui/material";
import React from "react";

import FlexBetween from "components/FlexBetween";

import Header from "components/Header";

import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// import SidebarContent from "components/SidebarContent"

import CustomColumnMenu from "components/DataGridCustomColumnMenu";

import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  Delete,
  Edit,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import Sidebar from "components/Sidebar";

// import BreakdownChart from "components/BreakdownChart";

// import OverviewChart from "components/OverviewChart";

// import { useGetDashboardQuery } from "state/api";

import StatBox from "components/StatBox";
import Navbar from "components/Navbar";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ButtonGroup, MenuItem, Menu } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import PrintIcon from "@mui/icons-material/Print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ImFilter } from "react-icons/im";
import { TbArrowsSort } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppinCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOffOutlined,
  PointOfSaleOutlined,
  CalendarTodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  ContentCopyOutlined,
} from "@mui/icons-material";

import { TocOutlinedIcon } from "@mui/icons-material/TocOutlined";

import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";

import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";

import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";

import {} from "@mui/icons-material";

import { useState, useEffect } from "react";

import SidebarContent from "components/SidebarContent";

import { Menu as MenuIcon, IconButton } from "@mui/material";
import SidebarAllCategory from "components/SidebarAllCategories";

// import SidebarAllCategories from "components/SidebarAllCategories";

// import SidebarAllCategories from 'components/SidebarAllCategories'

const Update2 = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery(
    "(min-width: 400px, max-width: 1280px)"
  );
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  // const { data, isLoading } = useGetDashboardQuery();
  const [movieslist, setmovieslist] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [customer_saz1_app_updates, setCustomer_saz1_app_updates] = useState(
    []
  );
  const fetchData = () => {
    fetch("https://masatv.net/backendserver/api/customer_saz1_app_updates/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCustomer_saz1_app_updates(res.data);
        const filteredRows = res.data.filter(
          (row) =>
            row.url?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.description?.toLowerCase().includes(searchTerm.toLowerCase()) || // Add filter for "Active Status"
            row.created_date?.toLowerCase().includes(searchTerm.toLowerCase()) // Add filter for "Created Date"
        );
 
        setFilteredCategories(filteredRows);
      });
  };
  // console.log(movieslist);
  const [isSidebarContentOpen, setIsSidebarContentOpen] = useState(true);

  useEffect(() => {
    fetchData(); // Fetch initial data when the component mounts
  }, [searchTerm]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const openDeleteDialog = (_id) => {
    setDeleteDialogOpen(true);
    setDeleteItemId(_id);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteItemId(null);
  };

  const columns = [
    {
      // field: "image",

      headerName: "Image",

      flex: 1,
      renderCell: (params) => (
        <Avatar
          alt="User Avatar"
          // src={`${params.value}`}
          sx={{ width: 50, height: 50 }} // Customize the width and height of the avatars
        />
      ),

      headerName: "Image",

      flex: 1,
      width: 200,
      renderCell: (movies) => {
        return (
          <div>
            <Avatar alt="Movie Name" src={movies.row.kameraImage}>
              {" "}
              {movies.row.kameraImage}
            </Avatar>
          </div>
        );
      },
    },

    {
      field: "url",

      headerName: "URL",

      flex: 1,
    },

    {
      field: "description",

      headerName: "Description",

      flex: 1,
    },

    {
      field: "created_date",

      headerName: "Created Date",

      flex: 0.5,

      sortable: false,

      // renderCell: (params) => params.value.length,
    },

    {
      field: "action",

      headerName: "Action",

      flex: 1,
      renderCell: (params) => {
        const _id = params.row._id;
        const handleEditAction = (_id) => {
          navigate(`/SazpinUpdate/AddUpdate/${params.row._id}`);
          // Ikkada Edit Action Logic Raasko
          console.log(`Edit action for ID ${_id}`);
          // aah Edit Logic ikkada Add chesko
        };
        const handleDeleteAction = (_id) => {
          // Open the delete confirmation dialog
          openDeleteDialog(_id);
        };

        const handleDeleteConfirmation = () => {
          // Assuming you have an API endpoint for deleting by ID
          axios
            .delete(
              `https://masatv.net/backendserver/api/customer_saz1_app_updates/delete/${deleteItemId}`
            )
            .then((response) => {
              console.log(`Item with ID ${deleteItemId} deleted successfully.`);
              closeDeleteDialog(); // Close the dialog
              fetchData();
              // You might want to refresh your data after a successful delete
            })
            .catch((error) => {
              console.error(
                `Error deleting item with ID ${deleteItemId}:`,
                error
              );
            });
        };

        // const id = params.row.id; // Assuming 'id' is a unique identifier for the row

        // const handleEditAction = () => {
        //   // Ikkada Edit Action Logic Raasko
        //   console.log(`Edit action for ID `);
        //   // aah Edit Logic ikkada Add chesko
        // };
        // const handleDeleteAction = () => {
        //   // Ikkada Delete Action Logic Raasko
        //   console.log(`Delete action for ID `);
        //   // aah Delete Logic ikkada Add chesko
        // }

        return (
          <div>
            <IconButton
              onClick={handleEditAction}
              aria-label="Edit"
              color="primary"
            >
              <Edit />
            </IconButton>

            <text>|</text>

            <IconButton
              onClick={() => handleDeleteAction(params.row._id)}
              aria-label="Delete"
              color="secondary"
            >
              <Delete />
            </IconButton>
            <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this item?
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    },

    // renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
  ];

  // const [movieslist, setmovieslist] = useState([]);
  // useEffect(() => {
  //   fetch("https://masatv.net/backendserver/api/categories/find")
  //     .then((response) => response.json())
  //     .then((json) => setmovieslist(json.data));
  // }, []);
  // console.log(movieslist);

  return (
    <Grid
      m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"}
      ml={isSmallScreen ? "10px" : "320px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      {/* m="1.5rem 2.5rem" ml="350px"> */}
      {/* <SidebarAllCategories /> */}

      <FlexBetween>{/* <Header title="Movie Category"  /> */}</FlexBetween>

      {/* Below Grid Contains Statbox and DataGrid */}
      <Grid flexDirection={isSmallScreen ? "column" : "row"}>
        <Box
          mt={isSmallScreen ? "10px" : "50px"}
          // mt="50px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="250px"
          gap={isSmallScreen ? "10px" : "0px"}
          // gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}

          <StatBox
            title="APP UPDATES"
            // value={data && data.totalCustomers}

            // increase="+14%"

            // description="Since last month"

            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
            searchtab={
              <div className="content">
                <div
                  id="data_table_wrapper"
                  className="dataTables_wrapper"
                  role="grid"
                >
                  <div className="dataTables_filter" id="data_table_filter">
                  <label>
                      Search : &nbsp;&nbsp;
                      <input
                        type="text"
                        aria-controls="data_table"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                 </label>
                  </div>
                </div>
              </div>
            }
          />

          {/* Table */}
          <Grid sx={{ height: 450, mt: isSmallScreen ? "-20px" : "-30px" }}>
            <Box
              backgroundColor={theme.palette.background.alt}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
                borderRadius: "0.55rem",
                height: "60px",
                mt: "-160px",
                overflowX: "auto",
              }}
            >
              <Button
                onClick={() => navigate("/SazpinUpdate/AddUpdate")}
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  ml: "5px",
                  height: "30px",
                  maxwidth: "100px",
                }}
              >
                <AddIcon sx={{ ml: "-5px" }} /> Add New
              </Button>
              <CSVLink
                  data={customer_saz1_app_updates}
                  filename="CustomerSaz1AppUpdates.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "30px",
                      maxWidth: "100px",
                    }}
                  >
                    <IosShareIcon sx={{ ml: "-5px", fontSize: "17px" }} />
                    Export
                  </Button>
                </CSVLink>
                
              
            </Box>

            <DataGrid
              sx={{ mt: isSmallScreen ? "10px" : "10px" }}
              getRowId={(row) => row._id}
              // checkboxSelection
              rows={filteredCategories}
              columns={columns}
            />
          </Grid>
        </Box>
      </Grid>

      <CustomColumnMenu />

      {/* <SidebarAllCategory /> */}
      <FlexBetween>
        <IconButton
          onClick={() => setIsSidebarContentOpen(!isSidebarContentOpen)}
        >
          <MenuIcon />
        </IconButton>
      </FlexBetween>
    </Grid>
  );
};

export default Update2;
