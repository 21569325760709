
import React, { useState, useEffect } from "react";
// import Joi from "joi";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Snackbar,
  Alert,
  useMediaQuery,
  Avatar
} from "@mui/material";
 
// import React, { useState } from 'react';
// import { TextField, Button, ButtonGroup, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Box , } from '@mui/material';
 
import { Input } from "@mui/material";
import axios from "axios";
import { CloudUpload, Category } from "@mui/icons-material";
import SidebarContent from "components/SidebarContent";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import InputFileUpload from "components/ChooseFile";
import { useParams } from "react-router-dom";
// import SelectImageSection from 'components/ChooseFile';
 
const AddTimeShift = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [checked, setChecked] = useState(false);
  const [passchecked, setPassChecked] = useState(false);
  const { _id } = useParams();
  const [visiblechecked, setVisibleChecked] = useState(false);
 
  // const [validationError, setValidationError] = useState(null);
 
  // const validationSchema = Joi.object(
  //   {
  //     streamName: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(1).max(30).required().label("streamName"),
  //     visible: Joi.allow('').allow(null),
  //     stream_URL: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("stream_URL"),
  //     server_IP: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('server_IP'),
  //     comments: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("comments"),
  //     original_stream: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('original_stream'),
  //     Fallback_stream_2: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('Fallback_stream_2'),
  //     Fallback_stream_1: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('Fallback_stream_1'),
  //     Fall_back_stream2: Joi.string().allow('').allow(null),
  //     Fall_back_stream1: Joi.string().allow('').allow(null),
  //     select_channel_image: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('select_channel_image'),
  //     select_the_category: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(1).max(30).required().label('select_the_category'),
  //     kameraImage: Joi.string().allow('').allow(null),
  //   });
 
  const [formData, setFormData] = useState({
    streamName: "",
    visible: "1",
    // title: '',
    stream_URL: "",
    server_IP: "",
    comments: "",
    original_stream: "",
    Fall_back_stream1: "",
    Fall_back_stream2: "",
    comments: "",
    select_channel_image: "",
    select_the_category: "",
    kameraImage:""
  });
 
  const handleReset = () => {
    setFormData({
      streamName: "",
      visible: "1",
      // title: '',
      stream_URL: "",
      server_IP: "",
      comments: "",
      original_stream: "",
      Fall_back_stream1: "",
      Fall_back_stream2: "",
      select_channel_image: "",
      select_the_category: "",
      kameraImage:""
    });
  };
 
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submittedImage, setSubmittedImage] = useState(null);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
        //Handling Image Change
        const handleImageChange = (e) => {
          const file = e.target.files[0];
     
          if (file) {
            const reader = new FileReader();
     
            reader.onload = () => {
              if (reader.result) {
                setFormData((prevData) => ({
                  ...prevData,
                  kameraImage: reader.result.split(',')[1], // Store as base64-encoded string
                }));
              }
            };
     
            reader.readAsDataURL(file);
          }
        };
     
        // Function to decode Base64 image and display it
        const renderBase64Image = () => {
          if (formData.kameraImage) {
            return (
              <Avatar
                alt="Image"
                src={`data:image/png;base64,${formData.kameraImage}`}
                sx={{ width: 100, height: 100 }}
              />
            );
          }
          return null;
        };
 
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      visible: prevData.visible ? 0 : 1,
    }));
    console.log(formData.visible);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a Message ID is available in the URL, fetch Message data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/videolives/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.kameraImage) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                  streamName:productData,
                  // visible: "1",
                  // title: '',
                  stream_URL:productData.stream_URL,
                  server_IP:productData.server_IP,
                  comments:productData.comments,
                  original_stream:productData.original_stream,
                  Fall_back_stream1:productData.Fall_back_stream1,
                  Fall_back_stream2:productData.Fall_back_stream2,
                  select_channel_image:productData.select_channel_image,
                  select_the_category:productData.select_the_category,
                  kameraImage:base64Image,
              });
            };
            img.src = `data:image/png;base64,${productData.kameraImage}`;
          } else {
            setFormData({
              streamName:productData,
                  // visible: "1",
                  // title: '',
                  stream_URL:productData.stream_URL,
                  server_IP:productData.server_IP,
                  comments:productData.comments,
                  original_stream:productData.original_stream,
                  Fall_back_stream1:productData.Fall_back_stream1,
                  Fall_back_stream2:productData.Fall_back_stream2,
                  select_channel_image:productData.select_channel_image,
                  select_the_category:productData.select_the_category,
                  kameraImage:"",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // const validationResult = validationSchema.validate(formData, {
    //   abortEarly: false,
    // });
 
    // if (validationResult.error) {
    //   setValidationError(validationResult.error.details);
    //   return ;
    // }
 
    // setValidationError(null);
 
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/videolives/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData) // Use axios.patch for the PATCH request
        .then((response) => {
          setSuccessMessage("Timeshift updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/videolives/post"; // Add
      axios
        .post(apiUrl, formData) // Use axios.post for the POST request
        .then((response) => {
          setSuccessMessage("Timeshift added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
 
  const [videolivecategories, setvideolivecategories] = useState([]);
 
  const fetchData = () => {
    fetch("https://masatv.net/backendserver/api/video_live_categories/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setvideolivecategories(res.data);
      });
  };
 
  useEffect(() => {
    fetchData(); // Fetch initial data when the component mounts
  }, []);
 
  return (
    // <Box m="1.5rem 2.5rem" ml="250px">
    <Box
      m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"}
      ml={isSmallScreen ? "10px" : "350px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      <FlexBetween>
        <Header title="ADD CHANNEL" />
 
        <Box></Box>
      </FlexBetween>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="streamName"
          label="Title"
          variant="outlined"
          value={formData.streamName}
          onChange={handleChange}
          margin="normal"
        />
     
        <FormControlLabel
        label="Visible"
        labelPlacement="start"
        control={<Checkbox
            name="visible"
            checked={formData.visible}
            onChange={handleCheckboxToggle}
          />}
        />
 
        <TextField
          fullWidth
          name="stream_URL"
          label="Stream_URL"
          variant="outlined"
          value={formData.stream_URL}
          onChange={handleChange}
          margin="normal"
        />
 
        <TextField
          fullWidth
          name="server_IP"
          label="Server_IP"
          variant="outlined"
          value={formData.server_IP}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="comments"
          label="Comments"
          variant="outlined"
          value={formData.comments}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="original_stream"
          label="Original Stream"
          variant="outlined"
          value={formData.original_stream}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="Fallback_stream_1"
          label="Fall Back Stream 1"
          variant="outlined"
          value={formData.Fallback_stream_1}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="Fallback_stream_2"
          label="Fallback Stream 2"
          variant="outlined"
          value={formData.Fallback_stream_2}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="select_channel_image"
          label="Select Channel Image"
          variant="outlined"
          value={formData.select_channel_image}
          onChange={handleChange}
          margin="normal"
        />
       
        Image:
       <FormControl fullWidth margin="normal">
            <Input id="image-upload" type="file" onChange={handleImageChange} />
          </FormControl>
          {renderBase64Image()}
       
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="select_the_category">Select Category</InputLabel>
          <Select
            label="Categories"
            name="select_the_category"
            // value={formData.country}
            onChange={handleChange}
          >
            <MenuItem>(Choose Category Type)</MenuItem>
            {videolivecategories.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
 
 
 
        {/* {validationError && (
          <div style={{ color: 'red' }}>
            {validationError.map((error) => (
              <div>{error.message}</div>
            ))}
            </div>
      )} */}
 
 
 
 
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button type="reset" onClick={handleReset}>
            Reset
          </Button>
 
          <Button type="submit">Submit</Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
 
export default AddTimeShift;
 