import { CSVLink } from "react-csv";
import { Grid } from "@mui/material";

import React from "react";

import FlexBetween from "components/FlexBetween";
import { DataGrid } from "@mui/x-data-grid";

import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import {
  Email,
  Edit,
} from "@mui/icons-material";

import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,

} from "@mui/material";

import StatBox from "components/StatBox";
import IosShareIcon from "@mui/icons-material/IosShare";


import {} from "@mui/icons-material";

import { useState, useEffect } from "react";

// import { Menu as MenuIcon, IconButton } from "@mui/material";

import Navbar from "components/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AllPackages = () => {
//   const navigate = useNavigate();
  const theme = useTheme();

  // const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  // const isNonMobile = useMediaQuery("(min-width:600px)");
  const isNonMediumScreens = useMediaQuery(
    "(min-width: 400px, max-width: 1280px)"
  );
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  // const { data, isLoading } = useGetDashboardQuery();
  const [openDialog, setOpenDialog] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'success' or 'error'

const handleEditAction = (id) => {
    setSelectedId(id);
    setOpenDialog(true); // Open the dialog
  };

  const handleCancel = () => {
    setOpenDialog(false); // Close the dialog
    setDropdownValue("");
  };

  // Snackbar close handler
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleUpdate = () => {
    const apiUrl = `https://masatv.net/backendserver/api/devices/patch/${selectedId}`; // API endpoint with selectedId
  
    // Send the dropdownValue as part of an object
    axios
      .patch(apiUrl, { planType: dropdownValue }) // Assuming `planType` is the key the server expects
      .then((response) => {
        console.log("Update successful:", response.data);
        // Optionally, refresh data or handle UI updates here
        fetchData();
        setSnackbarMessage("Plan Updated successfully"); // Set the success message
        setSnackbarSeverity("success"); // Set severity to success
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Error updating:", err);
        setSnackbarMessage("Update failed!"); // Set the error message
        setSnackbarSeverity("error"); // Set severity to error
        setSnackbarOpen(true);
      });
  
    setOpenDialog(false); // Close the dialog after updating
  };

  const columns = [
    {
      field: "mac_address",

      headerName: "Mac Address",

      flex: 1,

    },

    {
      field: "app_type",

      headerName: "App Type",

      flex: 1,
    },
    {
      field: "is_trial",

      headerName: "Is Trial",

      flex: 1,
    },
    {
      field: "expire_date",

      headerName: "Expiry Date",

      flex: 1,
    },
    {
        field: "action",
        headerName: "Action",
        flex: 1,
        renderCell: (params) => {
          const _id = params.row._id;
          return (
            <Tooltip title="Edit" placement="top"
                componentsProps={{
                    tooltip: {
                    sx: {
                        fontSize: '0.875rem', // Adjust the size as needed
                        padding: '8px 10px',   // Optional: Adjust padding to make the tooltip larger
                    }
                    }
                }}
            >
                <IconButton
                    onClick={() => handleEditAction(_id)}
                    aria-label="Edit"
                    color="primary"
                >
                    <Edit />
                </IconButton>
            </Tooltip>
          );
        },
      },

  ];

  const [devices, setDevices] = useState([]);
  useEffect(() => {
    fetchData();
  }, [searchTerm]);
  console.log(devices);

  const fetchData = () => {
    fetch("https://masatv.net/backendserver/api/devices/getdevices")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setDevices(res.data);
        const filteredRows = res.data.filter((row) =>
        (
          row.mac_address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.app_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //   row.is_trial?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.expire_date?.toLowerCase().includes(searchTerm.toLowerCase()) 
        )
      );
    //   console.log("Filtered Rows:", filteredRows);
      setFilteredCategories(filteredRows);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

  return (
    <Box
      m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"}
      ml={isSmallScreen ? "10px" : "40px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      {/* m="1.5rem 2.5rem" ml="310px"> */}

      <Navbar />

      <FlexBetween>{/* <Header title="Live TV Category"  /> */}</FlexBetween>

      {/* Below Grid Contains Statbox and DataGrid */}
      <Grid flexDirection={isSmallScreen ? "column" : "row"}>
        <Box
          mt={isSmallScreen ? "10px" : "50px"}
          // mt="50px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="250px"
          gap={isSmallScreen ? "10px" : "0px"}
          // gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* ROW 1 */}

          <StatBox
            title="DEVICES"
            // value={data && data.totalCustomers}

            // increase="+14%"

            // description="Since last month"

            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
            searchtab={
              <div className="content">
                <div
                  id="data_table_wrapper"
                  className="dataTables_wrapper"
                  role="grid"
                >
                  <div className="dataTables_filter" id="data_table_filter">
                  <label>
                      Search : &nbsp;&nbsp;
                      <input
                        type="text"
                        aria-controls="data_table"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            }
          />

          {/* Table */}
          <Grid sx={{ height: 450, mt: isSmallScreen ? "-20px" : "-30px" }}>
            <Box
              backgroundColor={theme.palette.background.alt}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
                borderRadius: "0.55rem",
                height: "60px",
                mt: "-160px",
                overflowX: "auto",
              }}
            >
              <CSVLink
                  data={devices}
                  filename="devices.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "30px",
                      maxWidth: "100px",
                      marginLeft: "10px"
                    }}
                  >
                    <IosShareIcon sx={{ ml: "-5px", fontSize: "17px" }} />
                    Export
                  </Button>
                </CSVLink>

            </Box>

            <DataGrid
              sx={{ mt: isSmallScreen ? "10px" : "10px" }}
              getRowId={(row) => row._id}
              // checkboxSelection
              rows={filteredCategories}
              columns={columns}
            />
          </Grid>
        </Box>
      </Grid>

      <CustomColumnMenu />

      {/* Dialogue Box */}
      <Dialog
        open={openDialog}
        onClose={handleCancel}
        fullWidth // Ensures it takes full available width
        maxWidth="md" // Adjust this to 'sm', 'md', 'lg', 'xl' as per your requirement
        sx={{
                "& .MuiDialog-paper": {
                width: "300px", // Set your custom width
                height: "200px", // Set your custom height
                },
            }}
        >
        <DialogTitle>Select the Plan</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="dropdown-label">Select Plan</InputLabel>
            <Select
              labelId="dropdown-label"
              value={dropdownValue}
              onChange={(e) => setDropdownValue(e.target.value)}
            >
              <MenuItem value="yearly">Yearly</MenuItem>
              <MenuItem value="lifetime">Lifetime</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary" sx={{ fontWeight: '900' }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>


        {/* Snackbar component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>


    </Box>
  );
};

export default AllPackages;
