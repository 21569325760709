
import React, { useState, useEffect } from "react";
import axios from "axios"
// import Joi from "joi";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  Snackbar,
  Alert,
  Avatar,
  Input
} from "@mui/material";
// import Sidebar from 'screens/content';
// import { ContentCopy } from '@mui/icons-material';
import FlexBetween from "components/FlexBetween";
 
import Header from "components/Header";
import SidebarContent from "components/SidebarContent";
import InputFileUpload from "components/ChooseFile";
 
const Addmovie = () => {
  const [checked, setChecked] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
 
  // const [validationError, setValidationError] = useState(null);
 
  // const validationSchema = Joi.object(
  //   {
  //   price: Joi.number().required().precision(2).label("price"),
  //   producer: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("producer"),
  //   year: Joi.number().precision(4).required().label(" year"),
  //   ratings: Joi.number().precision(2).required().label(" ratings"),
  //   actors: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("actors"),
  //   studio: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("studio"),
  //   director: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("director"),
  //   language: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("language"),
  //   duration: Joi.string().regex(/^([01]\d|2[0-3]):([0-5]\d)$/).required().label(" duration"),  
  //   description: Joi.string().regex(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/).min(3).max(30).required().label('description'),
  //   trailer: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("trailer"),
  //   // title: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("title"),
  //   pc: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(1).max(30).required().label("pc"),
  //   stream_for_webtv: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("stream_for_webtv"),
  //   stream_for_ios_mobile: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(1).max(30).required().label("stream_for_ios_mobile"),
  //   stream_for_android: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(1).max(30).required().label("stream_for_android"),
  //   android_setup_box: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(1).max(30).required().label("android_setup_box"),
  //   custom_linux_box: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("custom_linux_box"),
  //   dreambox: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/ ).min(3).max(30).required().label("dreambox"),
  //   visible: Joi.allow('').allow(null),
  //   kameraUrl_pc_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_dream_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_linux_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_setup_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_android_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_ios_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_secondary: Joi.string().allow('').allow(null),
  //   modified: Joi.string().allow('').allow(null),
  //   position: Joi.string().allow('').allow(null),
  //   created: Joi.string().allow('').allow(null),
  //   category_id: Joi.string().allow('').allow(null),
  //   kameraImage: Joi.string().allow('').allow(null),
  //   kameraDurum: Joi.string().allow('').allow(null),
  //   streamName: Joi.string().allow('').allow(null),
  //   kameraUrl_pc: Joi.string().allow('').allow(null),
  //   kameraUrl_dream: Joi.string().allow('').allow(null),
  //   kameraUrl_linux: Joi.string().allow('').allow(null),
  //   kameraUrl_setup: Joi.string().allow('').allow(null),
  //   kameraUrl_android: Joi.string().allow('').allow(null),
  //   kameraUrl_ios: Joi.string().allow('').allow(null),
  //   kameraUrl: Joi.string().allow('').allow(null),
 
 
  // });
 
  const { _id } = useParams();
  const [formData, setFormData] = useState({
    kameraUrl:"",
      kameraUrl_ios:"",
      kameraUrl_android:"",
      kameraUrl_setup:"",
      kameraUrl_linux:"",
      kameraUrl_dream:"",
      kameraUrl_pc:"",
      streamName:"",
      kameraDurum:"",
      kameraImage:"",
      category_id:"",
      created:"",
      position:"",
      modified:"",
      kameraUrl_secondary:"",
      kameraUrl_ios_secondary:"",
      kameraUrl_android_secondary:"",
      kameraUrl_setup_secondary:"",
      kameraUrl_linux_secondary:"",
      kameraUrl_dream_secondary:"",
      kameraUrl_pc_secondary:"",
      visible:"1",
      trailer:"",
      description:"",
      language:"",
      duration:"",
      year:"",
      studio:"",
      producer:"",
      director:"",
      actors:"",
      ratings:"",
      price:"",
      // title:"",
  });
 
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
 
  const handleReset = () => {
    setFormData({
      kameraUrl:"",
      kameraUrl_ios:"",
      kameraUrl_android:"",
      kameraUrl_setup:"",
      kameraUrl_linux:"",
      kameraUrl_dream:"",
      kameraUrl_pc:"",
      streamName:"",
      kameraDurum:"",
      kameraImage:"",
      category_id:"",
      created:"",
      position:"",
      modified:"",
      kameraUrl_secondary:"",
      kameraUrl_ios_secondary:"",
      kameraUrl_android_secondary:"",
      kameraUrl_setup_secondary:"",
      kameraUrl_linux_secondary:"",
      kameraUrl_dream_secondary:"",
      kameraUrl_pc_secondary:"",
      visible:"1",
      trailer:"",
      description:"",
      language:"",
      duration:"",
      year:"",
      studio:"",
      producer:"",
      director:"",
      actors:"",
      ratings:"",
      price:"",
      // title:"",
    });
  };
  const [submittedImage, setSubmittedImage] = useState(null);
 
        //Handling Image Change
        const handleImageChange = (e) => {
          const file = e.target.files[0];
     
          if (file) {
            const reader = new FileReader();
     
            reader.onload = () => {
              if (reader.result) {
                setFormData((prevData) => ({
                  ...prevData,
                  kameraImage: reader.result.split(',')[1], // Store as base64-encoded string
                }));
              }
            };
     
            reader.readAsDataURL(file);
          }
        };
     
        // Function to decode Base64 image and display it
        const renderBase64Image = () => {
          if (formData.kameraImage) {
            return (
              <Avatar
                alt="Image"
                src={`data:image/png;base64,${formData.kameraImage}`}
                sx={{ width: 100, height: 100 }}
              />
            );
          }
          return null;
        };
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value,  });
  };
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      visible: prevData.visible ? 0 : 1,
    }));
    console.log(formData.visible);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // const validationResult = validationSchema.validate(formData, {
    //   abortEarly: false,
    // });
 
    // if (validationResult.error) {
    //   setValidationError(validationResult.error.details);
    //   return ;
    // }
 
    // setValidationError(null);
   
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/videos/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData)
        .then((response) => {
          setSuccessMessage("Movies updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/videos/post"; // Add
      axios
        .post(apiUrl, formData)
        .then((response) => {
          setSuccessMessage("Movies added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/videos/video/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.kameraImage) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                kameraUrl:productData.kameraUrl,
                kameraUrl_ios:productData.kameraUrl_ios,
                kameraUrl_android:productData.kameraUrl_android,
                kameraUrl_setup:productData.kameraUrl_setup,
                kameraUrl_linux:productData.kameraUrl_linux,
                kameraUrl_dream:productData.kameraUrl_dream,
                kameraUrl_pc:productData.kameraUrl_pc,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:base64Image,
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                modified:productData.modified,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                kameraUrl_ios_secondary:productData.kameraUrl_ios_secondary,
                kameraUrl_android_secondary:productData.kameraUrl_android_secondary,
                kameraUrl_setup_secondary:productData.kameraUrl_setup_secondary,
                kameraUrl_linux_secondary:productData.kameraUrl_linux_secondary,
                kameraUrl_dream_secondary:productData.kameraUrl_dream_secondary,
                kameraUrl_pc_secondary:productData.kameraUrl_pc_secondary,
                visible:productData.visible,
                trailer:productData.trailer,
                description:productData.description,
                language:productData.language,
                duration:productData.duration,
                year:productData.year,
                studio:productData.studio,
                producer:productData.producer,
                director:productData.director,
                actors:productData.actors,
                ratings:productData.ratings,
                price:productData.price,
              });
            };
            img.src = `data:image/png;base64,${productData.kameraImage}`;
          } else {
            setFormData({
              kameraUrl:productData.kameraUrl,
              kameraUrl_ios:productData.kameraUrl_ios,
              kameraUrl_android:productData.kameraUrl_android,
              kameraUrl_setup:productData.kameraUrl_setup,
              kameraUrl_linux:productData.kameraUrl_linux,
              kameraUrl_dream:productData.kameraUrl_dream,
              kameraUrl_pc:productData.kameraUrl_pc,
              streamName:productData.streamName,
              kameraDurum:productData.kameraDurum,
              kameraImage:"",
              category_id:productData.category_id,
              created:productData.created,
              position:productData.position,
              modified:productData.modified,
              kameraUrl_secondary:productData.kameraUrl_secondary,
              kameraUrl_ios_secondary:productData.kameraUrl_ios_secondary,
              kameraUrl_android_secondary:productData.kameraUrl_android_secondary,
              kameraUrl_setup_secondary:productData.kameraUrl_setup_secondary,
              kameraUrl_linux_secondary:productData.kameraUrl_linux_secondary,
              kameraUrl_dream_secondary:productData.kameraUrl_dream_secondary,
              kameraUrl_pc_secondary:productData.kameraUrl_pc_secondary,
              visible:productData.visible,
              trailer:productData.trailer,
              description:productData.description,
              language:productData.language,
              duration:productData.duration,
              year:productData.year,
              studio:productData.studio,
              producer:productData.producer,
              director:productData.director,
              actors:productData.actors,
              ratings:productData.ratings,
              price:productData.price,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
 
  const [categories, setCategories] = useState([]);
 
  // Make an API request to fetch payment types data from your server
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/categories/find")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCategories(res.data);
      });
  }, []);
 
  return (
    <Box m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "300px"} mt={isSmallScreen ? "70px" : "40px"}>
      <FlexBetween>
        <Header title="ADD MOVIE" />
 
        <Box></Box>
      </FlexBetween>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="streamName"
          label="Title"
          variant="outlined"
          value={formData.streamName}
          onChange={handleChange}
          margin="normal"
        />
        <FormControlLabel
          label="Visible"
          labelPlacement="start"
          control={<Checkbox
            name="visible"
            checked={formData.visible}
            onChange={handleCheckboxToggle}
            />}
          />
 
        <TextField
          fullWidth
          name="stream_for_webtv"
          label="Stream for Webtv"
          variant="outlined"
          value={formData.stream_for_webtv}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="stream_for_ios_mobile"
          label="Stream for IOS mobile"
          variant="outlined"
          value={formData.stream_for_ios_mobile}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="stream_for_android"
          label="Stream for Android"
          variant="outlined"
          value={formData.stream_for_android}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="android_setup_box"
          label="Android Setup box"
          variant="outlined"
          value={formData.android_setup_box}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="custom_linux_box"
          label="Custom linux box"
          variant="outlined"
          value={formData.custom_linux_box}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="dreambox"
          label="Dreambox"
          variant="outlined"
          value={formData.dreambox}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="pc"
          label="PC"
          variant="outlined"
          value={formData.pc}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="trailer"
          label="Add Trailer"
          variant="outlined"
          value={formData.trailer}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="description"
          label="Description"
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          name="language"
          label="Language"
          variant="outlined"
          value={formData.language}
          onChange={handleChange}
          margin="normal"
        />
       
 
        <TextField
          fullWidth
          name="duration"
          label="Duration"
          variant="outlined"
          value={formData.duration}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="year"
          label="Year"
          variant="outlined"
          value={formData.year}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="studio"
          label="Studio"
          variant="outlined"
          value={formData.studio}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="producer"
          label="Producer"
          variant="outlined"
          value={formData.producer}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="director"
          label="Director"
          variant="outlined"
          value={formData.director}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="actors"
          label="Actors"
          variant="outlined"
          value={formData.actors}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="ratings"
          label="Ratings"
          variant="outlined"
          value={formData.ratings}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="price"
          label="Price"
          variant="outlined"
          value={formData.price}
          onChange={handleChange}
          margin="normal"
        />
        <br />
        <label>Upload Image :</label> &nbsp;
      <Input type="file" onChange={handleImageChange} />
 
 
      <br/>
      {/* Display the Base64 image */}
      {renderBase64Image()}
{/* <br></br> */}
       
       
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="category">Category</InputLabel>
          <Select
            label="Category"
            name="category_id"
            value={formData.category_id}
            onChange={handleChange}
          >
            <MenuItem value="">(Choose Category Type)</MenuItem>
            {categories.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
               ))}
 
            {/* Add more language options as needed */}
          </Select>
        </FormControl>
 
 
        {/* {validationError && (
          <div style={{ color: 'red' }}>
            {validationError.map((error) => (
              <div>{error.message}</div>
            ))}
            </div>
      )} */}
 
 
 
 
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button type="reset" onClick={handleReset}>Reset</Button>
          <Button type="submit">Submit</Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
 
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
 
export default Addmovie;