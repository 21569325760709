
 
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import Joi from "joi";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  Alert,
  Snackbar,
  
  Avatar,
  Input
} from "@mui/material";
// import { CloudUpload } from '@mui/icons-material';
import SidebarContent from "components/SidebarContent";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import InputFileUpload from "components/ChooseFile";
 
const Addchannel = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
  // const [validationError, setValidationError] = useState(null);
  // const validationSchema = Joi.object(
  //   // const timeSchema = Joi.date().iso().strict().max('now').required(),
  //   { price: Joi.string().allow('').allow(null),
  //   ratings: Joi.string().allow('').allow(null),
  //   duration: Joi.string().allow('').allow(null),
  //   // duration: Joi.number().precision(2).required().label(" duration"),
  //   enter_stream_url: Joi.string().allow('').allow(null),
  //   producer: Joi.string().allow('').allow(null),
  //   director: Joi.string().allow('').allow(null),
  //   studio: Joi.string().allow('').allow(null),
  //   actors: Joi.string().allow('').allow(null),
  //   language:Joi.string().allow('').allow(null),
  //   // anguage: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("language"),
  //   stream_for_webtv: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("producer"),
  //   backup_stream_1: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("director"),
  //   backup_stream_2: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("studio"),
  //   backup_stream_3: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("actors"),
  //   backup_stream_4: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("language"),
  //   backup_stream_4: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("streamName"),
  //   backup_stream_5: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("description"),
  //   backup_stream_6: Joi.string().regex(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/).min(3).max(30).required().label("description"),
  //   // visible: Joi.string().allow('').allow(null),
  //   id: Joi.string().allow('').allow(null),
  //   kameraUrl_ios: Joi.string().allow('').allow(null),
  //   kameraUrl_android: Joi.string().allow('').allow(null),
  //   kameraUrl_android_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_dream: Joi.string().allow('').allow(null),
  //   kameraUrl_dream_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl: Joi.string().allow('').allow(null),
  //   kameraUrl_setup: Joi.string().allow('').allow(null),
  //   kameraUrl_linux: Joi.string().allow('').allow(null),
  //   kameraUrl_dream: Joi.string().allow('').allow(null),
  //   kameraUrl_pc: Joi.string().allow('').allow(null),
  //   kameraDurum: Joi.string().allow('').allow(null),
  //   kameraUrl_ios_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_setup_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_linux_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_dream_secondary: Joi.string().allow('').allow(null),
  //   kameraUrl_pc_secondary: Joi.string().allow('').allow(null),
  //   is_humax: Joi.string().allow('').allow(null),
  //   probe_status: Joi.string().allow('').allow(null),
  //   visible: Joi.allow('').allow(null),
  //   kameraImage: Joi.string().allow('').allow(null),
  //   streamName: Joi.string().allow('').allow(null),
  //   category_id: Joi.string().allow('').allow(null),
  //   created: Joi.string().allow('').allow(null),
  //   position: Joi.string().allow('').allow(null),
  //   kameraUrl_secondary: Joi.string().allow('').allow(null),
  // });
  const [formData, setFormData] = useState({
    id: "",
    kameraUrl: "",
    kameraUrl_ios: "",
    kameraUrl_android: "",
    kameraUrl_setup: "",
    kameraUrl_linux: "",
    kameraUrl_dream: "",
    kameraUrl_pc: "",
    streamName: "",
    kameraDurum: "",
    kameraImage: "",
    category_id: "",
    created: "",
    position: "",
    kameraUrl_secondary: "",
    kameraUrl_ios_secondary: "",
    kameraUrl_android_secondary: "",
    kameraUrl_setup_secondary: "",
    kameraUrl_linux_secondary: "",
    kameraUrl_dream_secondary: "",
    kameraUrl_pc_secondary: "",
    is_humax: "",
    visible: "1",
    probe_status: "",
  });
 
  const handleReset = () => {
    setFormData({
      id: "",
      kameraUrl: "",
      kameraUrl_ios: "",
      kameraUrl_android: "",
      kameraUrl_setup: "",
      kameraUrl_linux: "",
      kameraUrl_dream: "",
      kameraUrl_pc: "",
      streamName: "",
      kameraDurum: "",
      kameraImage: "",
      category_id: "",
      created: "",
      position: "",
      kameraUrl_secondary: "",
      kameraUrl_ios_secondary: "",
      kameraUrl_android_secondary: "",
      kameraUrl_setup_secondary: "",
      kameraUrl_linux_secondary: "",
      kameraUrl_dream_secondary: "",
      kameraUrl_pc_secondary: "",
      is_humax: "",
      visible: "1",
      probe_status: "",
    });
  };
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submittedImage, setSubmittedImage] = useState(null);
 
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
        //Handling Image Change
        const handleImageChange = (e) => {
          const file = e.target.files[0];
     
          if (file) {
            const reader = new FileReader();
     
            reader.onload = () => {
              if (reader.result) {
                setFormData((prevData) => ({
                  ...prevData,
                  kameraImage: reader.result.split(',')[1], // Store as base64-encoded string
                }));
              }
            };
     
            reader.readAsDataURL(file);
          }
        };
     
        // Function to decode Base64 image and display it
        const renderBase64Image = () => {
          if (formData.kameraImage) {
            return (
              <Avatar
                alt="Image"
                src={`data:image/png;base64,${formData.kameraImage}`}
                sx={{ width: 100, height: 100 }}
              />
            );
          }
          return null;
        };
 
  const handleCheckboxToggle = () => {
   
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      visible: prevData.visible ? 0 : 1,
    }));
    console.log(formData.visible);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/livetvs/${_id}`)
        .then((response) => {
          const productData = response.data;
          if (productData.kameraImage) {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              const base64Image = canvas.toDataURL("image/png").split(',')[1];
 
              setFormData({
                id:productData.id,
                kameraUrl:productData.kameraUrl,
                kameraUrl_ios:productData.kameraUrl_ios,
                kameraUrl_android:productData.kameraUrl_android,
                kameraUrl_setup:productData.kameraUrl_setup,
                kameraUrl_linux:productData.kameraUrl_linux,
                kameraUrl_dream:productData.kameraUrl_dream,
                kameraUrl_pc:productData.kameraUrl_pc,
                streamName:productData.streamName,
                kameraDurum:productData.kameraDurum,
                kameraImage:base64Image,
                category_id:productData.category_id,
                created:productData.created,
                position:productData.position,
                kameraUrl_secondary:productData.kameraUrl_secondary,
                kameraUrl_ios_secondary:productData.kameraUrl_ios_secondary,
                kameraUrl_android_secondary:productData.kameraUrl_android_secondary,
                kameraUrl_setup_secondary:productData.kameraUrl_setup_secondary,
                kameraUrl_linux_secondary:productData.kameraUrl_linux_secondary,
                kameraUrl_dream_secondary:productData.kameraUrl_dream_secondary,
                kameraUrl_pc_secondary:productData.kameraUrl_pc_secondary,
                is_humax:productData.is_humax,
                probe_status:productData.probe_status,
              });
            };
            img.src = `data:image/png;base64,${productData.kameraImage}`;
          } else {
            setFormData({
              id:productData.id,
              kameraUrl:productData.kameraUrl,
              kameraUrl_ios:productData.kameraUrl_ios,
              kameraUrl_android:productData.kameraUrl_android,
              kameraUrl_setup:productData.kameraUrl_setup,
              kameraUrl_linux:productData.kameraUrl_linux,
              kameraUrl_dream:productData.kameraUrl_dream,
              kameraUrl_pc:productData.kameraUrl_pc,
              streamName:productData.streamName,
              kameraDurum:productData.kameraDurum,
              kameraImage:"",
              category_id:productData.category_id,
              created:productData.created,
              position:productData.position,
              kameraUrl_secondary:productData.kameraUrl_secondary,
              kameraUrl_ios_secondary:productData.kameraUrl_ios_secondary,
              kameraUrl_android_secondary:productData.kameraUrl_android_secondary,
              kameraUrl_setup_secondary:productData.kameraUrl_setup_secondary,
              kameraUrl_linux_secondary:productData.kameraUrl_linux_secondary,
              kameraUrl_dream_secondary:productData.kameraUrl_dream_secondary,
              kameraUrl_pc_secondary:productData.kameraUrl_pc_secondary,
              is_humax:productData.is_humax,
              probe_status:productData.probe_status,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }
  }, [_id]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // const validationResult = validationSchema.validate(formData, {
    //   abortEarly: false,
    // });
 
    // if (validationResult.error) {
    //   setValidationError(validationResult.error.details);
    //   return ;
    // }
 
    // setValidationError(null);
    // console.log(formData);
    console.log(formData);
 
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/livetvs/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData) // Use axios.patch for the PATCH request
        .then((response) => {
          setSuccessMessage("LiveTv updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/livetvs/post"; // Add
      axios
        .post(apiUrl, formData) // Use axios.post for the POST request
        .then((response) => {
          setSuccessMessage("LiveTv added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
 
  const [Addshowepisodes, setaddshowepisodes] = useState([]);
  useEffect(() => {
    fetch("https://masatv.net/backendserver/api/livetvs/find")
      .then((response) => response.json())
      .then((json) => setaddshowepisodes(json.data));
  }, []);
  console.log(Addshowepisodes);
 
  return (
    // <Box m="1.5rem 2.5rem" ml="250px">
    <Box
      m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"}
      ml={isSmallScreen ? "10px" : "300px"}
      mt={isSmallScreen ? "70px" : "40px"}
    >
      <FlexBetween>
        <Header title="ADD CHANNEL" />
 
        <Box></Box>
      </FlexBetween>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="streamName"
          label="Title"
          variant="outlined"
          value={formData.streamName}
          onChange={handleChange}
          margin="normal"
        />
 
<FormControlLabel
    label="Visible"
    labelPlacement="start"
    control={<Checkbox
            name="visible"
            checked={formData.visible}
            onChange={handleCheckboxToggle}
          />}
    />
 
        <TextField
          fullWidth
          name="stream_for_webtv"
          label="Stream for Webtv"
          variant="outlined"
          value={formData.stream_for_webtv}
          onChange={handleChange}
          margin="normal"
        />
 
        <TextField
          fullWidth
          name="backup_stream_1"
          label="Backup Stream 1"
          variant="outlined"
          value={formData.backup_stream_1}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="backup_stream_2"
          label="Backup Stream 2"
          variant="outlined"
          value={formData.backup_stream_2}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="backup_stream_3"
          label="Backup Stream 3"
          variant="outlined"
          value={formData.backup_stream_3}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="backup_stream_4"
          label="Backup Stream 4"
          variant="outlined"
          value={formData.backup_stream_4}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="backup_stream_5"
          label="Backup Stream 5"
          variant="outlined"
          value={formData.backup_stream_5}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          name="backup_stream_6"
          label="Backup Stream 6"
          variant="outlined"
          value={formData.backup_stream_6}
          onChange={handleChange}
          margin="normal"
        />
 
 <br />
        <label>Upload Image :</label> &nbsp;
      <Input type="file" onChange={handleImageChange} />
 
 
      <br/>
      {/* Display the Base64 image */}
      {renderBase64Image()}
{/* <br></br> */}
        {/* <TextField
        fullWidth
        name="select_channel_image"
        label="Select Channel Image"
        variant="outlined"
        value={formData.select_channel_image}
        onChange={handleChange}
        margin="normal"
         
      /> */}
 
        {/* <TextField
        fullWidth
        name="select_channel_image"
        label={<SelectImageSection/>}
        variant="outlined"
        value={formData.select_channel_image}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={3}
         
      /> */}
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="category">Select the Category</InputLabel>
          <Select
            label="Category"
            name="streamName"
            value={formData.streamName}
            onChange={handleChange}
          >
            <MenuItem>(Choose Category)</MenuItem>
            {Addshowepisodes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.streamName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
 
 
        {/* {validationError && (
          <div style={{ color: 'red' }}>
            {validationError.map((error) => (
              <div>{error.message}</div>
            ))}
            </div>
      )} */}
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button type="reset" onClick={handleReset}>
            Reset
          </Button>
          <Button type="submit">Submit</Button>
        </ButtonGroup>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
      >
        {/* <Alert
        onClose={handleCloseSnackbar}
        severity={validationError ? "error" : "success"}
        variant="filled"
      >
        {validationError
          ? "Validation Error. Please check the form."
          : successMessage}
        </Alert> */}
      </Snackbar>
    </Box>
  );
};
 
export default Addchannel;
 