
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  useMediaQuery,
  Snackbar,
  Alert,
  Avatar
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Input } from "@mui/material";
 
import { CloudUpload } from "@mui/icons-material";
 
import SidebarContent from "components/SidebarContent";
 
import FlexBetween from "components/FlexBetween";
 
import Header from "components/Header";
 
import InputFileUpload from "components/ChooseFile";
 
 
// import Adminsidebar from 'components/adminsidebar';
 
const AddMessage12 = () => {
  const [checked, setChecked] = useState(false);
  const { _id } = useParams();
  const [passchecked, setPassChecked] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
 
  const [visiblechecked, setVisibleChecked] = useState(false);
 
  const [formData, setFormData] = useState({
    title: "",
 
    description: "",
 
    created_date: "",
    image: "",
    status: 1,
  });
 
  const handleReset = () => {
    setFormData({
      title: "",
 
      description: "",
 
      created_date: "",
      image: "",
      status: "",
          });
  };
 
 
  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
 
    setFormData({ ...formData, [name]: value });
  };
 
  //Handling Image Change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
 
    if (file) {
      const reader = new FileReader();
 
      reader.onload = () => {
        if (reader.result) {
          setFormData((prevData) => ({
            ...prevData,
            image: reader.result.split(',')[1], // Store as base64-encoded string
          }));
        }
      };
 
      reader.readAsDataURL(file);
    }
  };
 
  //Render Image while Patch
  // Function to decode Base64 image and display it
  const renderBase64Image = () => {
    if (formData.image) {
      return (
        <Avatar
          alt="Image"
          src={`data:image/png;base64,${formData.image}`}
          sx={{ width: 100, height: 100 }}
        />
      );
    }
    return null;
  };

  const handleCheckboxToggle = () => {
    
    // Update form data state on toggle change
    setFormData((prevData) => ({
      ...prevData,
      status: prevData.status ? 0 : 1,
    }));
    console.log(formData.status);
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  // const [paymentTypes, setPaymentTypes] = useState([]);
 
  useEffect(() => {
    if (_id) {
      // If a product ID is available in the URL, fetch product data and pre-fill the form
      //if you want to fetch the data with name if Name is Unique then use name also..
      axios
        .get(`https://masatv.net/backendserver/api/saz2_adds/${_id}`)
        .then((response) => {
          const productData = response.data;
 
         // Decode the image and set it in the formData
         if (productData.image) {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const base64Image = canvas.toDataURL("image/png").split(',')[1];
           
            setFormData({
              title: productData.title,
              description: productData.description,
              created_date: productData.created_date,
              image: base64Image,
            });
          };
          img.src = `data:image/png;base64,${productData.image}`;
        } else {
          setFormData({
            title: productData.title,
            description: productData.description,
            created_date: productData.created_date,
            image: "", // Set to empty string if there is no image
          });
        }
      })
        .catch((error) => {
          console.error('Error fetching content data:', error);
        });
    }
  }, [_id]);
 
 
  const handleSubmit = (e) => {
    e.preventDefault();
 
    if (_id) {
      // If _id is defined, it's an edit operation
      const apiUrl = `https://masatv.net/backendserver/api/saz2_adds/patch/${_id}`; // Edit
      axios
        .patch(apiUrl, formData) // Use axios.patch for the PATCH request
        .then((response) => {
          setSuccessMessage("Saz2 Ads updated successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // If _id is not defined, it's an add operation
      const apiUrl = "https://masatv.net/backendserver/api/saz2_adds/post"; // Add
      axios
        .post(apiUrl, formData) // Use axios.post for the POST request
        .then((response) => {
          setSuccessMessage("Saz2 Ads added successfully!");
          setSnackbarOpen(true);
          handleReset();
        })
        .catch((err) => {
          console.error(err);
        });
    }
 
 
    console.log(formData);
  };
 
 
  const [shahid_new_users, setshahidnewusers] = useState([]);
 
//     fetch("https://masatv.net/backendserver/api/products/list")
 
 
//     fetch("https://masatv.net/backendserver/api/products/list")
//       .then((res) => res.json())
//       .then((res) => {
//         console.log(res);
//         setProducts(res.data);
//       });
 
useEffect(()=>
{
 
 
  fetch("https://masatv.net/backendserver/api/shahidnewusersRoutes/get")
  .then((response) => response.json())
  .then((json) => setshahidnewusers(json.data));
 
},[]);
console.log(shahid_new_users);
    // fetch("https://masatv.net/backendserver/api/masa_new_users/find")
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log(res);
    //     setProducts(res.data);
    //   });
 
 
 
 
 
 
  return (
    <Box m={isSmallScreen ? "1rem" : "1.5rem 2.5rem"} ml={isSmallScreen ? "10px" : "350px"} mt={isSmallScreen ? "70px" : "40px"}>
      <FlexBetween>
        <Box></Box>
      </FlexBetween>
 
      <h3>APPMESSAGE ADD </h3>
 
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="title"
          label="Title"
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
          margin="normal"
          required
        />
 
 
        <TextField
          fullWidth
          name="description"
          label="Description"
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          required
        />
 
 <FormControlLabel
    label="Active"
    labelPlacement="start"
    control={<Checkbox
            name="status"
            checked={formData.status}
            onChange={handleCheckboxToggle}
          />}
    />
 
        <br />
        <label>Upload Image :</label> &nbsp;
      <Input type="file" onChange={handleImageChange} />
 
 
      <br/>
      {/* Display the Base64 image */}
      {renderBase64Image()}
        <br></br>
        <ButtonGroup variant="contained" aria-label="outlined button group">
          <Button onClick={handleReset} style={{ backgroundColor: "White", color: "black" }}>
            Reset
          </Button>
          <Button type = "submit" style={{ backgroundColor: "green" }}>Submit</Button>
        </ButtonGroup>
      </form>
 
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
 
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {successMessage}
        </Alert>
      </Snackbar>
 
      {/* <Adminsidebar /> */}
    </Box>
  );
};
 
export default AddMessage12;
 